import React, {useEffect, useState} from 'react';
import axios from 'axios';
import '../style.css';
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import {useSelector} from 'react-redux';
import { selectEmployeeId } from '../Slice/SelectEmployeeId';

function LeaveManagement() {
    const [leaves, setLeaves] = useState([]);
    const [pendingLeaves, setPendingLeaves] = useState([]);
    const [employeeId, setEmployeeId] = useState(useSelector(selectEmployeeId));
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('https://hrm.seaways-ship.com/getLeaves/' + employeeId)
        .then(res => {
            // console.log(res.data.Result);
            const newLeaves = [];
            const pendingLeaves = [];
            for (let i = 0; i < res.data.Result.length; ++i) {
                if (res.data.Result[i].approved === 1) {
                    newLeaves.push({start: new Date(res.data.Result[i].start_date), end: new Date(res.data.Result[i].end_date), title: res.data.Result[i].leave_type});
                } else if (res.data.Result[i].approved === 0 && res.data.Result[i].declined === 0) {
                    res.data.Result[i].approved = "Pending";
                    pendingLeaves.push(res.data.Result[i]);
                }
            }
            setLeaves(newLeaves);
            setPendingLeaves(pendingLeaves);
        })
        .catch(err => console.log(err));
    }, [])

    // useEffect(() => {
    //     console.log(leaves);
    //   }, [leaves]);

    const handleClick = (event) => {
        event.preventDefault();
        navigate('/createLeave');
    }

    const formatLeave = ({ start, end, title }) => {
        return `${title}`; //(${start.toLocaleDateString()} - ${end.toLocaleDateString()})
      };

    return (
        <div style={{textAlign: 'center'}}>
            <div>
                <h3>Pending Leave Requests</h3>
                <table CELLPADDING='6' style={{ margin: '0 auto' }}>
                    <tr>
                        <th>Leave ID</th>
                        <th>Leave Type</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Status</th>
                    </tr>
                    {pendingLeaves.map(data => {
                        return <tr>
                            <td>{data.id}</td>
                            <td>{data.leave_type}</td>
                            <td>{data.start_date}</td>
                            <td>{data.end_date}</td>
                            <td>{data.approved}</td>
                        </tr>
                    })}
                </table>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                    <br />
                    <h3>Upcoming Leaves</h3>
                    <Calendar
                        tileContent={({ date }) => {
                        const leave = leaves.find(l => date >= l.start && date <= l.end);
                        return leave ? <div>{formatLeave(leave)}</div> : null;
                        }}
                    />
                </div>
            </div> <br />
            <button onClick={handleClick}>
                Apply For Leave
            </button>
        </div>
    )
}

export default LeaveManagement;
