import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';
import { selectEmployeeId } from '../Slice/SelectEmployeeId';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useNavigate } from 'react-router-dom';

function HistoricalPayroll() {
    const [histroicalPayrolls, setHistoricalPayrolls] = useState([]);
    const [employeeId, setEmployeeId] = useState(useSelector(selectEmployeeId));

    const navigate = useNavigate();

    useEffect(() => {
        axios.get('https://hrm.seaways-ship.com/getHistoricalPayrolls')
        .then(res => {
            const tempList = [];
            for (var i = 0; i < res.data.Result.length; ++i) {
                tempList.push(res.data.Result[i]);
            }
            setHistoricalPayrolls(tempList);
        })
        .catch(err => console.log(err));
    }, [])

    const handleReturn = () => {
        navigate('/payroll');
    }

    return (
        <div>
            <div style={{position: 'relative', top: '-50px', right: '-10px'}}>
                <button style={{backgroundColor: 'transparent', border: 'none', color: '#000'}} onClick={handleReturn}>
                    <i className='bi bi-arrow-left-circle-fill' style={{fontSize: '30px'}}></i>
                </button>
            </div>
            <table CELLPADDING='6' style={{position: 'relative', top: '-40px'}}>
                <tr>
                    <th>Date</th>
                    <th>Salary</th>
                    <th>Bonus</th>
                    <th>Double Pay</th>
                    <th>Leave Pay</th>
                    <th>MPF mandatory contribution</th>
                    <th>MPF voluntary contribution</th>
                    <th>Total Pay</th>
                    <th>Others</th>
                    <th>Special</th>
                    <th>Total Deductions</th>
                    <th>Net Pay</th>
                </tr>
                {histroicalPayrolls.map(data => {
                    return <tr>
                        <td>{data.date}</td>
                        <td>{data.salary}</td>
                        <td>{data.bonus}</td>
                        <td>{data.double_pay}</td>
                        <td>{data.leave_pay}</td>
                        <td>{data.mpf_mandatory_contrib}</td>
                        <td>{data.mpf_voluntary_contrib}</td>
                        <td>{data.total_pay}</td>
                        <td>{data.others}</td>
                        <td>{data.special}</td>
                        <td>{data.total_deductions}</td>
                        <td>{data.net_pay}</td>
                    </tr>
                })}
            </table>
            {histroicalPayrolls.length === 0 && <p style={{textAlign: 'center'}}>There are no previous payroll records</p>}
        </div>
    )
}

export default HistoricalPayroll;