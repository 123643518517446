import React, {useState} from 'react';
import axios from 'axios';
import '../style.css';
import { useNavigate } from 'react-router-dom'

function CreateAccount() {
    const [userData, setUserData] = useState({
        email: '',
        password: '',
        username: '',
        role: 'Managing Director'
    });
    const [error, setError] = useState('');
    const [confirmPwd, setConfirmPwd] = useState();
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(userData.username);

        if (userData.password === confirmPwd && userData.password !== '') {
            var permissions = "", remaining_annual_leave = 0;
            if (userData.role === "HR and Admin Manager") {
                permissions = "alter_payroll,alter_leaves,alter_claims,view_appraisal,alter_appraisal"; remaining_annual_leave = 16;
            } else if (userData.role === "Managing Director") {
                permissions = "alter_leaves,view_appraisal"; remaining_annual_leave = 26;
            } else if (userData.role === "Director") {
                permissions = "alter_leaves,view_appraisal"; remaining_annual_leave = 24;
            } else if (userData.role === "Marine Manager" || userData.role === "Fleet Manager" || userData.role === "Crew Manager") {
                permissions = "alter_leaves,view_appraisal"; remaining_annual_leave = 18;
            } else if (userData.role === "Vessel Manager (Marine, HSSEQ and Ops)" || userData.role === "Vessel Manager (Tech)"
                || userData.role === "IT Manager") {
                permissions = "alter_leaves,view_appraisal"; remaining_annual_leave = 16;
            } else if (userData.role === "Marine Officer" || userData.role === "Technical and Purchasing Officer" || userData.role === "Crew Officer") {
                permissions = ""; remaining_annual_leave = 12;
            }
            const values = [userData.username, userData.email, userData.password, userData.role, permissions, remaining_annual_leave, 0];
            axios.post('https://hrm.seaways-ship.com/createUser', {data: values})
            .then(res => {
                if (res.data.Status === "Error") console.log(res.data.Error);
                else {
                    console.log(res.data.Status);
                    setShowSuccessMsg(true);
                }
            })
            .catch(err => console.log(err));
        } else if (userData.username === '') setError('Empty username field');
        else if (userData.email === '') setError('Empty email field');
        else if (userData.password === '') setError('Empty password field');
        else setError("The password does not match");
    }

    const onClose = () => {
        setShowSuccessMsg(false);
        navigate('/login');
    }

    const handleReturnToLogin = () => {
        navigate('/login');
    }

    return (
        <div className='d-flex justify-content-center align-items-center vh-100 loginPage'>
            <div className='p-3 rounded w-25 border loginForm'>
                <div className='text-danger'>
                    {error && error}
                </div>
                <h4>Create your account</h4>
                <h6>Create an account on the Seaways HR to manage your HR related tasks</h6>
                <form onSubmit={handleSubmit}>
                    <label>Name</label> <br />
                    <input type="text" onChange={event => setUserData({...userData, username: event.target.value})} className='form-control rounded-0'></input> <br />
                    <label>Email</label> <br />
                    <input type="text" onChange={event => setUserData({...userData, email: event.target.value})} className='form-control rounded-0'></input> <br />
                    <label>Role</label> <br />
                    <select className='form-control rounded-0' onChange={(event) => setUserData({...userData, role: event.target.value})}>
                        <option value="Managing Director">Managing Director</option>
                        <option value="Director">Director</option>
                        <option value="Marine Manager">Marine Manager</option>
                        <option value="Vessel Manager (Marine, HSSEQ and Ops)">Vessel Manager (Marine, HSSEQ and Ops)</option>
                        <option value="Fleet Manager">Fleet Manager</option>
                        <option value="Vessel Manager (Tech)">Vessel Manager (Tech)</option>
                        <option value="IT Manager">IT Manager</option>
                        <option value="HR and Admin Manager">HR and Admin Manager</option>
                        <option value="Marine Officer">Marine Officer</option>
                        <option value="Technicql and Purchasing Officer">Technical and Purchasing Officer</option>
                        <option value="Crew Manager">Crew Manager</option>
                        <option value="Crew Officer">Crew Officer</option>
                    </select> <br />
                    <label>Password</label> <br />
                    <input type='password' onChange={event => setUserData({...userData, password: event.target.value})} className='form-control rounded-0'></input> <br />
                    <label>Confirm Password</label> <br />
                    <input type='password' onChange={event => setConfirmPwd(event.target.value)} className='form-control rounded-0'></input> <br />
                    <button type='submit' className='btn btn-success w-100 rounded-0 mb-3'>Create Account</button>
	            <button onClick={handleReturnToLogin} className='btn btn-secondary w-100 rounded-0'>Back To Login</button>
                </form>
                {showSuccessMsg && 
                <div className='popupOverlay'>
                    <div className='popupContainer'>
                        <div className='popupMessage' style={{color: 'black'}}>
                            The account has been created
                            <br /><br />
                            <button onClick={onClose} style={{textAlign: 'center'}}>Return to login page</button>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default CreateAccount;
