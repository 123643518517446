import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import '../style.css';
import axios from 'axios';

function Home() {
    // const location = useLocation();
    const [employeeId, setEmployeeId] = useState();

    useEffect(() => {
        // console.log(employeeId)
        // setEmployeeId(location.state.employeeId)
	 axios.get('https://hrm.seaways-ship.com/getEmployeeId')
        .then(res => {
            console.log(res.data.Result);
            setEmployeeId(res.data.Result);
        })
        .catch (err => console.log(err));
     })

    return (
        <div className='d-flex justify-content-center align-items-center vh-100 loginPage'>
	    <table style={{textAlign: 'center', color: 'white'}}>
                <tr><h3>Welcome to the Seaways Ship Management (HK) Limited HR Management page</h3></tr>
                <tr><h5>Please browse this system in order to manage your HR account</h5></tr>
                <tr><h5>Your HR ID: {employeeId}</h5></tr>
            </table>
        </div>
    )
}

export default Home;
