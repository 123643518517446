import React, {useState, useEffect} from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link, Outlet } from 'react-router-dom'
import '../style.css';
import {useSelector} from 'react-redux';
import { selectEmployeeId } from '../Slice/SelectEmployeeId';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
    const [employeeId, setEmployeeId] = useState(useSelector(selectEmployeeId));
    const [permissions, setPermissions] = useState('');

    const navigate = useNavigate();
    axios.defaults.withCredentials = true;

    async function fetchData() {
  try {
    const response = await axios.get('https://hrm.seaways-ship.com/getPermissions');
    console.log(response.data.Status);
    if (response.data.Result[0].permissions !== '') {
           const result = response.data.Result[0].permissions.split(",");
           setPermissions(result);
         } else {
           setPermissions(response.data.Result[0].permissions);
         }
  } catch (error) {
    console.log(error);
  }
}

    useEffect(() => {
	fetchData();
//        axios.get('https://hrm.seaways-ship.com/getPermissions')
//       .then(res => {
//	    console.log(res.data.Status);
//            if (res.data.Result[0].permissions != '') {
//                const result = res.data.Result[0].permissions.split(",");
//                setPermissions(result);
                // console.log(result);
 //           }
 //           else {
 //               setPermissions(res.data.Result[0].permissions)
 //           }
 //       })
 //       .catch(err => console.log(err));

        axios.get('https://hrm.seaways-ship.com/dashboard')
		.then(res => {
			if(res.data.Status === "Success") {
				console.log(res.data.id);
			} else {
				navigate('/login')
			}
		})
    }, [])
    
    const handleLogout = () => {
        axios.get('https://hrm.seaways-ship.com/logout')
        .then(res => {
            console.log(res.data.Status);
        })
        .catch(err => console.log(err));
        navigate('/login');
    }

    return (
        <div className="container-fluid">
			<div className="row flex-nowrap">
				<div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
					<div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
						<a href="/" className="d-flex align-items-center pb-3 mb-md-1 mt-md-3 me-md-auto text-white text-decoration-none">
							<span className="fs-5 fw-bolder d-none d-sm-inline">Admin Dashboard</span>
						</a>
						<ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                            <div className="sideBarHover">
                                <li>
                                    <Link to="/" data-bs-toggle="collapse" className="nav-link text-white px-0 align-middle">
                                        <i className="fs-4 bi-speedometer2"></i> <span className="ms-1 d-none d-sm-inline">Dashboard</span> </Link>
                                </li>
                            </div>
                            <div className="sideBarHover">
                                <li>
                                    <Link to="/payroll" className="nav-link px-0 align-middle text-white">
                                        <i className="fs-4 bi-wallet"></i> <span className="ms-1 d-none d-sm-inline">Payroll</span> </Link>
                                </li>
                            </div>
                            <div className="sideBarHover">
                                <li>
                                    <Link to="/leaves" className="nav-link px-0 align-middle text-white">
                                        <i className="fs-4 bi-arrow-90deg-left"></i> <span className="ms-1 d-none d-sm-inline">Leaves management</span></Link>
                                </li>
                            </div>
                            <div className="sideBarHover">
                                <li>
                                    <Link to="/claims" className="nav-link px-0 align-middle text-white">
                                        <i className="fs-4 bi-cash"></i> <span className="ms-1 d-none d-sm-inline">Claims management</span></Link>
                                </li>
                            </div>
                            <div className="sideBarHover">
                                <li>
                                    <Link to="/appraisal" className="nav-link px-0 align-middle text-white">
                                        <i className="fs-4 bi-person"></i> <span className="ms-1 d-none d-sm-inline">Employee appraisal</span></Link>
                                </li>
                            </div>
                            {permissions !== '' && <div className="sideBarHover">
                                <li>
                                    <Link to="/manage" className="nav-link px-0 align-middle text-white">
                                        <i className="fs-4 bi-kanban"></i> <span className="ms-1 d-none d-sm-inline">Manage</span></Link>
                                </li>
                            </div>}
	     		    {permissions && permissions.includes("alter_payroll") && <div className="sideBarHover">
                                <li>
                                    <Link to="/claimHistory" className="nav-link px-0 align-middle text-white">
                                        <i className="fs-4 bi-coin"></i> <span className="ms-1 d-none d-sm-inline">Claims history</span></Link>
                                </li>
                            </div>}
	    		    {permissions && permissions.includes("alter_payroll") && <div className="sideBarHover">
                                <li>
                                    <Link to="/employeeDirectory" className="nav-link px-0 align-middle text-white">
                                        <i className="fs-4 bi-people"></i> <span className="ms-1 d-none d-sm-inline">Employee Directory</span></Link>
                                </li>
                            </div>}
                            <div className="sideBarHover">
                                <li onClick={handleLogout}>
                                    <a href="#" className="nav-link px-0 align-middle text-white">
                                        <i className="fs-4 bi-power"></i> <span className="ms-1 d-none d-sm-inline">Logout</span></a>
                                </li>
                            </div>
						</ul>
					</div>
				</div>
				<div class="col p-0 m-0">
					<div className='p-2 d-flex justify-content-center shadow'>
						<h4>HR Management</h4>						
					</div>
					<Outlet />
				</div>
			</div>
		</div>
    )
}

export default Dashboard;
