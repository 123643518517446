import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {useSelector} from 'react-redux';
import { selectEmployeeId } from '../Slice/SelectEmployeeId';

function ClaimsManagement() {
    const [employeeId, setEmployeeId] = useState(useSelector(selectEmployeeId));
    const [claims, setClaims] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('https://hrm.seaways-ship.com/getClaims/' + employeeId)
        .then(res => {
            console.log(res.data.Result[0])
            const newClaims = [];
            for (var i = 0; i < res.data.Result.length; ++i) {
                var status = "", settled = "";
                if (res.data.Result[i].approved === 0 && res.data.Result[i].declined === 0) {
                    status = "Pending";
                } else if (res.data.Result[i].approved === 0) {
                    status = "Declined";
                } else {
                    status = "Approved";
                }
                if (res.data.Result[i].settled === 0) settled = "No";
                else settled = "Yes";
                newClaims.push({id: res.data.Result[i].id, reason: res.data.Result[i].claim_reason,
                status: status, settled: settled});
            }
            setClaims(newClaims);
        })
        .catch(err => console.log(err));
    }, [])

    useEffect(() => {
        console.log(claims);
    }, [])

    const handleClick = (event) => {
        event.preventDefault();
        navigate('/createClaim');
    }

    return (
        <div style={{textAlign: 'center'}}>
            <div className='mt-5 mb-5 table-container d-flex justify-content-center align-items-center'>
                <div>
                    <h4><u>Applied claim statuses</u></h4>
                    <table CELLPADDING='6'>
                        <tr>
                            <th><h5><strong>Claim ID</strong></h5></th>
                            <th><h5><strong>Claim Reason</strong></h5></th>
                            <th><h5><strong>Status</strong></h5></th>
                            <th><h5><strong>Settled</strong></h5></th>
                        </tr>
                        {claims.map((claim, index) => {
                            return (
                            <tr>
                                <td>{claim.id}</td>
                                <td>{claim.reason}</td>
                                <td><strong>{claim.status}</strong></td>
                                <td><strong>{claim.settled}</strong></td>
                            </tr>
                            )
                        })}
                    </table>
                    {claims.length === 0 && <p>There are no applied claim requests</p>}
                </div>
            </div>
            <button onClick={handleClick}>Create New Claim</button>
        </div>
    )
}

export default ClaimsManagement;
