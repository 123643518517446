import React, {useEffect, useState} from "react";
import axios, { all } from "axios";

function ClaimHistory() {
    const [allClaims, setAllClaims] = useState([]);

    useEffect(() => {
        axios.get('https://hrm.seaways-ship.com/getAllResolvedClaims')
        .then(res => {
            console.log(res.data.Result);
            const tempArr = [];
            for (var i = 0; i < res.data.Result.length; ++i) {
                if (res.data.Result[i].approved === 1) res.data.Result[i].approved = "Yes";
                else if (res.data.Result[i].approved === 0) res.data.Result[i].approved = "No";

                if (res.data.Result[i].declined === 1) res.data.Result[i].declined = "Yes";
                else if (res.data.Result[i].declined === 0) res.data.Result[i].declined = "No";

                if (res.data.Result[i].settled === 1) res.data.Result[i].settled = "Yes";
                else if (res.data.Result[i].settled === 0) res.data.Result[i].settled = "No";
                tempArr.push(res.data.Result[i]);
            };
            setAllClaims(tempArr);
        })
        .catch(err => console.log(err));
    }, []);

    return (
    <div>
        <br />
        <h5 style={{textAlign: 'center'}}>All Historical Claims</h5>
        <table cellPadding={6}>
            <tr>
                <th>Claim ID</th>
                <th>Claimee Name</th>
                <th>Claimee ID</th>
                <th>Claim Amount</th>
                <th>Currency</th>
                <th>Claim Reason</th>
                <th>Claim Remarks</th>
	        <th>On Account Of</th>
                <th>Approved</th>
                <th>Declined</th>
                <th>Settled</th>
            </tr>
            {allClaims.map(data => {
                return <tr>
                    <td>{data.id}</td>
                    <td>{data.claimee_name}</td>
                    <td>{data.claimee_id}</td>
                    <td>{data.claim_amount}</td>
                    <td>{data.currency}</td>
                    <td>{data.claim_reason}</td>
                    <td>{data.claim_remarks}</td>
	            <td>{data.on_account_of}</td>
                    <td>{data.approved}</td>
                    <td>{data.declined}</td>
                    <td>{data.settled}</td>
                </tr>
            })}
        </table>
    </div>
    )
};

export default ClaimHistory;
