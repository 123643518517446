import React, {useState, useEffect} from 'react';
import Calendar from 'react-calendar';
import axios from 'axios';
import {useSelector} from 'react-redux';
import { selectEmployeeId } from '../Slice/SelectEmployeeId';
import '../style.css';
import { useNavigate } from 'react-router-dom';

function CreateLeave() {
    //All the leave application fields in database
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [leaveType, setLeaveType] = useState("Annual");
    const [manager, setManager] = useState();
    const [managerId, setManagerId] = useState();
    const [employeeName, setEmployeeName] = useState();

    const [remainingAnnualLeaves, setRemainingAnnualLeaves] = useState(10);
    const [actionAllowed, setActionAllowed] = useState(true);
    const [employeeId, setEmployeeId] = useState();
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [annualLeavesSelected, setAnnualLeavesSelected] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        //Get number of annual leaves remaining for this employee
        axios.get('https://hrm.seaways-ship.com/getRemainingLeaves/' + employeeId)
        .then(res => {
            setRemainingAnnualLeaves(res.data.Result[0].remaining_annual_leaves);
        })
        .catch(err => console.log(err));
        //Get name of employee
        axios.get('https://hrm.seaways-ship.com/getEmployeeName/' + employeeId)
        .then(res => {
            setEmployeeName(res.data.Result[0].username);
        })
        .catch(err => console.log(err));
	//Get ID of employee
        axios.get('https://hrm.seaways-ship.com/getEmployeeId')
        .then(res => {
            setEmployeeId(res.data.Result);
        })
        .catch(err => console.log(err));
    }, [])

    const onStartChange = (value) => {
        // setStartDate(value, () => {
        //     console.log(startDate);
        //   });
        const localValue = new Date(value.getTime() - (value.getTimezoneOffset() * 60000));
        setStartDate(localValue);
    }

    const onEndChange = (value) => {
        //If leave type is "annual", then check to see if the endDate - startDate is less than the remaining annual leave quota
        const localValue = new Date(value.getTime() - (value.getTimezoneOffset() * 60000));
        if (leaveType === "Annual") {
            const selectedDays = Math.ceil(
              (localValue.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
            );
            console.log(selectedDays);
            if (selectedDays > remainingAnnualLeaves || selectedDays <= 0) {
              setActionAllowed(false);
            } else {
              setEndDate(localValue); 
              setActionAllowed(true);
            }
          } else {
            setEndDate(localValue);
          }
    }

    const handleLeaveChange = (event) => {
        if (event.target.value !== "Annual") {
            setActionAllowed(true);
            setAnnualLeavesSelected(false);
        } else {
	    setAnnualLeavesSelected(true);
	}
        setLeaveType(event.target.value);
    }

    const handleManagerChange = (event) => {
        setManager(event.target.value);
    }

    const handleManagerIdChange = (event) => {
        setManagerId(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        //Post all this into the db
        if (actionAllowed === true) {
            const values = [
                Math.floor((Math.random() * 1000000) + 1),
                employeeId,
                startDate.toISOString().substr(0, 10),
                endDate.toISOString().substr(0, 10),
                managerId,
                leaveType,
                manager,
                employeeName,
                0, 
                0
            ]
            console.log(values);
            axios.post('https://hrm.seaways-ship.com/createLeave', { data: values })
            .then(res => {
                if (res.data.Status === "Error") console.log(res.data.Error);
                else {
                    setShowSuccessMsg(true);
                    const inputs = document.getElementsByTagName("input");
                    for (let i = 0; i < inputs.length; i++) {
                        inputs[i].value = "";   
                    }
                }
            })
            .catch(err => console.log(err));
        }
    }

    const onClose = () => {
        setShowSuccessMsg(false);
    }

    const handleReturn = () => {
        navigate('/leaves');
    }

    return (
        <div>
            <div style={{position: 'relative', top: '-50px', right: '-10px'}}>
                <button style={{backgroundColor: 'transparent', border: 'none', color: '#000'}} onClick={handleReturn}>
                    <i className='bi bi-arrow-left-circle-fill' style={{fontSize: '30px'}}></i>
                </button>
            </div>
            <form style={{textAlign: 'center', position: 'relative', top: '-40px'}}>
                <label for="type" className='mt-3'><h4>Please select the type of leave</h4></label> <br />
                <select id="type" name="leave type" onChange={handleLeaveChange} className='mb-3'>
                    <option value="Annual">Annual Leave</option>
                    <option value="Sick">Sick Leave</option>
                    <option value="Compassionate">Compassionate Leave</option>
                    <option value="No Pay"> No Pay Leave</option>
                    <option value ="Compensation">Compensation Leave</option>
                    <option value="Accident">Accident Leave</option>
                </select> <br />
	        {annualLeavesSelected && <p><b>{remainingAnnualLeaves} days</b> of annual leave remaining</p>}
                <label><h4>Please write the name of your manager</h4></label><br />
                <input type="text" id="manme" name="mname" className='mb-3' onChange={handleManagerChange}></input><br />
                <label><h4>Please write the id of your manager</h4></label><br />
                <input type="text" id="manme" name="mname" className='mb-3' onChange={handleManagerIdChange}></input>
                <h4>Please select the duration of the leave</h4>
                {!actionAllowed && <h6 style={{color: 'red'}}>The selected range is not valid. You only have {remainingAnnualLeaves} annual leave days left</h6>}
                <div className='mt-3' style={{columnCount: 2, paddingLeft: '10%'}}>
                    <Calendar onChange={onStartChange} value={startDate} />
                    <Calendar onChange={onEndChange} value={endDate} />
                </div>
                <button onClick={handleSubmit}>Submit</button>
            </form>
            {showSuccessMsg && 
                <div className='popupOverlay'>
                    <div className='popupContainer'>
                        <div className='popupMessage'>
                            The leave application is submitted
                            <br /><br />
                            <button onClick={onClose}>Close</button>
                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default CreateLeave;
