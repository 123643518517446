import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function EditPayroll() {
    const [payrollData, setPayrollData] = useState([0,0,0,0,0,0,0,0,0,0,0]);
    const [employeeId, setEmployeeId] = useState();
    const [oldData, setOldData] = useState([]);
    const [oldDataObj, setOldDataObj] = useState();
    const [showPayrollUpdatedMsg, setShowPayrollUpdatedMsg] = useState(false);

    const navigate = useNavigate();

    const handleChange = (value, index) => {
        setPayrollData([...payrollData.slice(0,index), value, ...payrollData.slice(index+1, 11)]);
    }

    useEffect(() => {
        axios.get('https://hrm.seaways-ship.com/getPayroll/' + employeeId)
        .then(res => {
            setOldData(Object.values(res.data.Result[0]));
            setOldDataObj(res.data.Result[0]);
        })
        .catch(err => console.log(err));
    }, [employeeId])

    const handleSubmit = (event) => {
        event.preventDefault();

        //Add old payroll into the histrocial payroll table
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = today.getFullYear();
        const formattedDate = `${dd}/${mm}/${yyyy}`;

        const historicalPayrollValues = [
            Math.floor((Math.random() * 1000000) + 1),
            formattedDate,
            employeeId,
            oldDataObj.bonus,
            oldDataObj.double_pay,
            oldDataObj.leave_pay,
            oldDataObj.mpf_mandatory_contrib,
            oldDataObj.mpf_voluntary_contrib,
            oldDataObj.net_pay,
            oldDataObj.others,
            oldDataObj.salary,
            oldDataObj.special,
            oldDataObj.total_deductions,
            oldDataObj.total_pay
        ];
        axios.post('https://hrm.seaways-ship.com/createHistoricalPayroll', {data: historicalPayrollValues})
        .then(res => {
            if (res.data.Status === "Error") console.log(res.data.Error);
        })
        .catch(err => console.log(err));

        //Update payroll table with new information
        const values = [];
        for (var i = 0; i < payrollData.length; ++i) {
            if (payrollData[i] === 0) {
                values.push(oldData[i]);
            } else {
                values.push(payrollData[i]);
            }
            if (i === 10) values.push(employeeId);
        }
        axios.post('https://hrm.seaways-ship.com/updatePayroll', {data: values})
        .then(res => {
            if (res.data.Status === "Error") console.log(res.data.Error);
            else {
                setShowPayrollUpdatedMsg(true);
            }
        })
        .catch(err => console.log(err));
        const inputs = document.getElementsByTagName("input");
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].value = "";   
        }
    }

    const onClose = () => {
        setShowPayrollUpdatedMsg(false);
        const inputs = document.getElementsByTagName("input");
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].value = "";   
        }
    }

    const handleReturn = () => {
        navigate('/manage');
    }

    return (
    <div>
        <div style={{position: 'relative', top: '-50px', right: '-10px'}}>
            <button style={{backgroundColor: 'transparent', border: 'none', color: '#000'}} onClick={handleReturn}>
                <i className='bi bi-arrow-left-circle-fill' style={{fontSize: '30px'}}></i>
            </button>
        </div>
        <div style={{position: 'relative', top: '-30px'}}>
            <h4 style={{textAlign: 'center'}}>Please fill in the payroll information</h4>
            <table CELLPADDING='6'>
                        <tr>
                            <td>Employee ID</td>
                            <td><input onChange={(event) => setEmployeeId(event.target.value)} required></input></td>
                        </tr>
                        <tr>
                            <th><h3><strong><u>Income</u></strong></h3></th>
                            <th><h3><strong><u>Amount</u></strong></h3></th>
                        </tr>
                        <tr>
                            <td>Salary</td>
                            <td><input onChange={(event) => handleChange(event.target.value, 0)}></input></td>
                        </tr>
                        <tr>
                            <td>Double pay</td>
                            <td><input onChange={(event) => handleChange(event.target.value, 1)}></input></td>
                        </tr>
                        <tr>
                            <td>Bonus</td>
                            <td><input onChange={(event) => handleChange(event.target.value, 2)}></input></td>
                        </tr>
                        <tr>
                            <td>Special</td>
                            <td><input onChange={(event) => handleChange(event.target.value, 3)}></input></td>
                        </tr>
                        <tr>
                            <td>Leave pay</td>
                            <td><input onChange={(event) => handleChange(event.target.value, 4)}></input></td>
                        </tr>
                        <tr>
                            <td><strong>Total pay</strong></td>
                            <td><input onChange={(event) => handleChange(event.target.value, 5)}></input></td>
                        </tr>
                        <br />
                        <tr>
                            <th><h3><strong><u>Deductions</u></strong></h3></th>
                            <th><h3><strong><u>Amount</u></strong></h3></th>
                        </tr>
                        <tr>
                            <td>MPF mandatory contribution</td>
                            <td><input onChange={(event) => handleChange(event.target.value, 6)}></input></td>
                        </tr>
                        <tr>
                            <td>MPF voluntary contribution</td>
                            <td><input onChange={(event) => handleChange(event.target.value, 7)}></input></td>
                        </tr>
                        <tr>
                            <td>Others</td>
                            <td><input onChange={(event) => handleChange(event.target.value, 8)}></input></td>
                        </tr>
                        <br />
                        <tr>
                            <th><h5><strong>Total Deductions</strong></h5></th>
                            <td><input onChange={(event) => handleChange(event.target.value, 9)}></input></td>
                        </tr>
                        <tr>
                        <th><h5><strong><u>Net Pay</u></strong></h5></th>
                        <td><input onChange={(event) => handleChange(event.target.value, 10)}></input></td>
                        </tr>
            </table>
            <button onClick={handleSubmit}>Submit</button>
        </div>
        {showPayrollUpdatedMsg && 
                <div className='popupOverlay'>
                    <div className='popupContainer'>
                        <div className='popupMessage'>
                            The payroll has been updated
                            <br /><br />
                            <button onClick={onClose} style={{textAlign: 'center'}}>Close</button>
                        </div>
                    </div>
                </div>}
    </div>
    );
}

export default EditPayroll;