import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';
import { selectEmployeeId } from '../Slice/SelectEmployeeId';
import { useNavigate } from 'react-router-dom';

function CreateClaim() {
    //These are the claim applicaiton fields in the database
    const [reason, setReason] = useState();
    const [amount, setAmount] = useState(0);
    const [remarks, setRemarks] = useState();
    const [currency, setCurrency] = useState("USD");
    const [accountCode, setAccountCode] = useState();
    const [onAccountOf, setOnAccountOf] = useState();

    const [employeeId, setEmployeeId] = useState();
    const [employeeName, setEmployeeName] = useState();
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        axios.get('https://hrm.seaways-ship.com/getEmployeeName/' + employeeId)
        .then(res => {
            setEmployeeName(res.data.Result[0].username);
        })
        .catch(err => console.log(err));

	axios.get('https://hrm.seaways-ship.com/getEmployeeId')
        .then(res => {
            console.log(res.data.Result);
		setEmployeeId(res.data.Result);
        })
        .catch(err => console.log(err));
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        const values = [
            Math.floor((Math.random() * 1000000) + 1),
            employeeId,
            amount,
            reason,
            remarks,
            false,
            false, 
            employeeName,
            false,
            currency,
		accountCode,
            onAccountOf
        ]
        axios.post('https://hrm.seaways-ship.com/createClaim', {data: values})
        .then(res => {
            if (res.data.Status === "Error") console.log(res.data.Error);
            else setShowSuccessMsg(true);
        })
        .catch(err => console.log(err));
    }

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    }

    const handleAmountChange = (event) => {
        setAmount(event.target.value);
    }

    const handleRemarksChange = (event) => {
        setRemarks(event.target.value);
    }
    
    const handleCurrencyChange = (event) => {
        setCurrency(event.target.value);
    }

	const handleAccountCodeChange = (event) => {
        setAccountCode(event.target.value);
    }

    const handleOnAccountOfChange = (event) => {
        setOnAccountOf(event.target.value);
    }

    // const handlePhotoChange = (event) => {
    //     // console.log(event.target.files[0]);
    //     // console.log(typeof event.target.files[0]);
    //     // setInvoice(event.target.files[0]);
    //     const reader = new FileReader();
    //     reader.readAsArrayBuffer(event.target.files[0]);
    //     reader.onload = () => {
    //         const invoiceBuffer = new Uint8Array(reader.result);
    //         setInvoice(invoiceBuffer);
    //     };
    // }

    const onClose = (event) => {
        setShowSuccessMsg(false);
//        const reasonInput = document.getElementById('reason');
//        reasonInput.value = '';
//        const amountInput = document.getElementById('amount');
//        amountInput.value = '';
//       const remarksInput = document.getElementById('remarks');
//        remarksInput.value = '';
	    const inputs = document.getElementsByTagName("input");
                    for (let i = 0; i < inputs.length; i++) {
                        inputs[i].value = "";
                    }
    }

    const handleReturn = () => {
        navigate('/claims');
    }

    return (
        <div>
            <div style={{position: 'relative', top: '-50px', right: '-10px'}}>
                <button style={{backgroundColor: 'transparent', border: 'none', color: '#000'}} onClick={handleReturn}>
                    <i className='bi bi-arrow-left-circle-fill' style={{fontSize: '30px'}}></i>
                </button>
            </div>
            <form style={{textAlign: 'center'}}>
                <h2 className='mt-5 mb-3'>Claim application form</h2>
                <label for="type" className='mt-3'><h4>Please state the reason for this claim</h4></label> <br />
                <input type="text" id="reason" name="reason" className='mb-3' size="50" onChange={handleReasonChange}></input> <br />
                <label for="type" className='mt-3'><h4>Please state the amount being claimed</h4></label> <br />
                <div>
                    <select style={{marginRight: '20px'}} onChange={handleCurrencyChange}>
                        <option value="USD">USD</option>
                        <option value="HKD">HKD</option>
                    </select>
                    <input type="text" id="amount" name="amount" className='mb-3' size="10" onChange={handleAmountChange}></input> <br />
                </div>
                {/* <label className='mt-3'><h4>Please attach a photo of the invoice for this claim</h4></label> <br />
                <input type="file" onChange={handlePhotoChange}></input> <br /> */}
	    <label className='mt-3'><h5>Account Code</h5></label> <br />
                <input className='mb-3' onChange={handleAccountCodeChange}></input> <br />
                <label className='mt-3'><h5>On Account Of</h5></label> <br />
                <input className='mb-3' onChange={handleOnAccountOfChange}></input> <br />
                <label for="type" className='mt-3'><h4>Please state any further remarks (if any)</h4></label> <br />
                <input type="text" id="remarks" name="remarks" className='mb-3' size="50" onChange={handleRemarksChange}></input> <br />
                <button onClick={handleSubmit}>Submit</button>
            </form>
            {showSuccessMsg && 
                <div className='popupOverlay'>
                    <div className='popupContainer'>
                        <div className='popupMessage'>
                            The claim application is submitted
                            <br /><br />
                            <button onClick={onClose}>Close</button>
                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default CreateClaim;
