import React, {useEffect, useState} from "react";
import axios from "axios";

function EmployeeDirectory() {
    const [employeeData, setEmployeeData] = useState([]);

    useEffect(() => {
        axios.get('https://hrm.seaways-ship.com/getAllEmployees')
        .then(res => {
            console.log(res.data.Result);
            const tempArr = [];
            for (var i = 0; i < res.data.Result.length; ++i) {
                if (res.data.Result[i].leaving_date === null) {
                    res.data.Result[i].status = "Active"; res.data.Result[i].leaving_date = "n/a"
                } else {
                    res.data.Result[i].status = "Left Company";
                }
                tempArr.push(res.data.Result[i]);
            }
            setEmployeeData(tempArr);
        })
        .catch(err => console.log(err));
    }, [])

    return (
        <div style={{ margin: '0 auto', textAlign: 'center' }}> <br />
            <h5 style={{textAlign: 'center'}}>Employee Record (Past & Present)</h5>
            <table cellPadding={6} style={{ margin: '0 auto' }}>
                <tr>
                    <th>Employee ID</th>
                    <th>Employee Name</th>
                    <th>Employee Position</th>
                    <th>Start Date</th>
                    <th>Last Date</th>
                    <th>Status</th>
                </tr>
                {employeeData.map(data => {
                    return <tr>
                        <td>{data.id}</td>
                        <td>{data.username}</td>
                        <td>{data.role}</td>
                        <td>{data.joining_date}</td>
                        <td>{data.leaving_date}</td>
                        <td>{data.status}</td>
                    </tr>
                })}
            </table>
        </div>
    )
}

export default EmployeeDirectory;
