import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';
import { selectEmployeeId } from '../Slice/SelectEmployeeId';
import '../style.css';
import { useNavigate } from 'react-router-dom';

function Manage() {
    const [employeeId, setEmployeeId] = useState(useSelector(selectEmployeeId));
    const [leaveRequestList, setLeaveRequestList] = useState([]);
    const [role, setRole] = useState();
    const [permissions, setPermissions] = useState('');
    const [keyAppraisalsList, setKeyAppraisalsList] = useState([]);
    const [showKeyAppraisalData, setShowKeyAppraisalData] = useState(false);
    const [keyAppraisalToShow, setKeyAppraisalToShow] = useState();
    const [keyAppraisalForHr, setKeyAppraisalForHr] = useState([]);
    const [selfAppraisalsList, setSelfAppraisalsList] = useState([]);
    const [showSelfAppraisalData, setShowSelfAppraisalData] = useState(false);
    const [selfAppraisalToShow, setSelfAppraisalToShow] = useState();
    const [allClaims, setAllClaims] = useState([]);
    const [allUnsettledClaims, setAllUnsettledClaims] = useState([]);
    const [showAnnualLeaveErrorMsg, setShowAnnualLeaveErrorMsg] = useState(false);
    const [selfAppraisalComments, setSelfAppraisalComments] = useState([]);
    const [selectedTab, setSelectedTab] = useState('Leaves');

    const navigate = useNavigate();

    useEffect(() => {
        //Get all leave requests with logged in user as manager
        axios.get('https://hrm.seaways-ship.com/getLeaveRequests/'+ employeeId)
        .then(res => {
            const tempList = [];
            for (var i = 0; i < res.data.Result.length; ++i) {
                tempList.push(res.data.Result[i]);
            }
            setLeaveRequestList(tempList);
        })
        .catch(err => console.log(err));

        //Get role of logged in user
        axios.get('https://hrm.seaways-ship.com/getRole/'+ employeeId)
        .then(res => {
            setRole(res.data.Result);
        })  
        .catch(err => console.log(err));

        //Get all key employee appraisals with logged in user as the supervisor
        axios.get('https://hrm.seaways-ship.com/getKeyAppraisal/'+ employeeId)
        .then(res => {
            const tempList = [];
            for (var i = 0; i < res.data.Result.length; ++i) {
                tempList.push(res.data.Result[i]);
            }
            setKeyAppraisalsList(tempList);
        })
        .catch(err => console.log(err));

        //Get all self appraisals with logged in user as the supervisor
        axios.get('https://hrm.seaways-ship.com/getSelfAppraisal/'+ employeeId)
        .then(res => {
            const tempList = [];
            for (var i = 0; i < res.data.Result.length; ++i) {
                console.log(res.data.Result[i]);
                tempList.push(res.data.Result[i]);
            }
            setSelfAppraisalsList(tempList);
        })
        .catch(err => console.log(err));

        //Get all the claims in the database
        axios.get('https://hrm.seaways-ship.com/getAllClaims')
        .then(res => {
            setAllClaims(res.data.Result);
        })
        .catch(err => console.log(err));

        //Get all unsettled claims in the database
        axios.get('https://hrm.seaways-ship.com/getAllUnsettledClaims')
        .then(res => {
            const tempList = [];
            for (var i = 0; i < res.data.Result.length; ++i) {
                tempList.push(res.data.Result[i]);
            }
            setAllUnsettledClaims(tempList);
        })
        .catch(err => console.log(err));

        //Get all the permission given to logged in user
        axios.get('https://hrm.seaways-ship.com/getPermissions/')
        .then(res => {
            console.log(res.data.Result);
            setPermissions(res.data.Result[0].permissions);
        })
        .catch(err => console.log(err));

        //Get all key appraisals not filled in by the HR manager
        axios.get('https://hrm.seaways-ship.com/getAllKeyAppraisal')
        .then(res => {
            const tempArr = [];
            for (var i = 0; i < res.data.Result.length; ++i) {
                tempArr.push(res.data.Result[i]);
            }
            setKeyAppraisalForHr(tempArr);
        })
    }, [])

    useEffect(() => {
        // console.log(permissions[0].permissions.includes('alter_leaves'));
        console.log(permissions);
    }, [permissions])

    const handleApprove = (leaveId, employeeId, leaveType, startDate, endDate) => {
        //Set approved field for this leave to 1
        const values = [1, leaveId];
        axios.post('https://hrm.seaways-ship.com/updateLeaveApproved', {data: values})
        .then(res => {
            if (res.data.Status === "Error") console.log(res.data.Error);
            else {
                //Remove this leave application from the list of pending applications
                const tempList = [];
                for (var i = 0; i < leaveRequestList.length; ++i) {
                    if (leaveRequestList[i].id === leaveId) {
                        continue;
                    } else {
                        tempList.push(leaveRequestList[i]);
                    }
                }
                if (leaveType === "Annual") {
                    console.log(employeeId); 
                    var remainingLeaves = 0, newRemainingLeaves = 0;
                    //Calculate the new remaining annual leaves value if the leave type is "Annual"
                    axios.get('https://hrm.seaways-ship.com/getRemainingLeaves/' + employeeId)
                    .then(res => {
                        remainingLeaves = res.data.Result[0].remaining_annual_leaves;
                        const diff = (new Date(endDate).getTime()) - (new Date(startDate).getTime());
                        const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24));
                        newRemainingLeaves = remainingLeaves - diffInDays;
                        if (newRemainingLeaves >= 0) {
                            //If there is enough quota, then change the annual leave quota for this employee
                            const values = [newRemainingLeaves, employeeId]
                            axios.post('https://hrm.seaways-ship.com/updateRemainingAnnualLeaves', {data: values})
                            .then(res => {
                                if (res.data.Status === "Error") console.log(res.data.Status);
                                else {
                                    setLeaveRequestList(tempList);
                                }
                            })
                            .catch(err => console.log(err));
                        } else {
                            //If there is not enough annual leaves left, change the approved field back to 0
                            const values = [0, leaveId];
                            axios.post('https://hrm.seaways-ship.com/updateLeaveApproved', {data: values})
                            .then(res => {
                                if (res.data.Status === "Error") console.log(res.data.Error);
                                else {
                                    setShowAnnualLeaveErrorMsg(true);
                                }
                            })
                            .catch(err => console.log(err));
                        }
                    })
                    .catch(err => console.log(err));
                } else {
                    //If leave type is not Annual, just remove from approval list 
                    setLeaveRequestList(tempList);
                }
            }
        })
        .catch(err => console.log(err));
    }

    const handleDecline = (leaveId) => {
        setShowAnnualLeaveErrorMsg(false);
        const values = [1, leaveId];
        axios.post('https://hrm.seaways-ship.com/updateLeaveDeclined', {data: values})
        .then(res => {
            if (res.data.Status === "Error") console.log(res.data.Error);
            else {
                const tempList = [];
                for (var i = 0; i < leaveRequestList.length; ++i) {
                    if (leaveRequestList[i].id === leaveId) {
                        continue;
                    } else {
                        tempList.push(leaveRequestList[i]);
                    }
                }
                setLeaveRequestList(tempList);
            }
        })
        .catch(err => console.log(err));
    }

    const handleKeyAppraisalData = (appraisalId) => {
        setShowKeyAppraisalData(true);
        for (var i = 0; i < keyAppraisalsList.length; ++i) {
            if (keyAppraisalsList[i].appraisal_id === appraisalId) {
                setKeyAppraisalToShow(keyAppraisalsList[i]);
            }
        }
    }

    const handleSelfAppraisalData = (appraisalId) => {
        setShowSelfAppraisalData(true);
        for (var i = 0; i < selfAppraisalsList.length; ++i) {
            if (selfAppraisalsList[i].appraisal_id === appraisalId) {
                setSelfAppraisalToShow(selfAppraisalsList[i]);
            }
        }
    }

    const handleKeyClose = () => {
        setShowKeyAppraisalData(false);
    }

    const handleSelfClose = () => {
        setShowSelfAppraisalData(false);
    }

    const handleButtonPress = (event, id) => {
        if (event.key === "Enter") {
            //Post comments into the db
            console.log("Test");
            console.log(selfAppraisalComments[0]);
            console.log(selfAppraisalComments[1]);
            const values = [selfAppraisalComments[0], selfAppraisalComments[1], id];
            axios.post('https://hrm.seaways-ship.com/updateSelfAppraisalComments', {data: values})
            .then(res => {
                if (res.data.Status === "Error") console.log(res.data.Error);
                else console.log(res.data.Status);
            })
            .catch(err => console.log(err));
        }
    }

    const handleClaimApproved = (claimID) => {
        const values = [claimID];
        axios.post('https://hrm.seaways-ship.com/updateClaimApproved', {data: values})
        .then (res => {
            if (res.data.Status === "Error") console.log(res.data.Error);
            else {
                const tempList = [];
                for (var i = 0; i < allClaims.length; ++i) {
                    if (allClaims[i].id === claimID) {
                        continue;
                    } else {
                        tempList.push(allClaims[i]);
                    }
                }
                setAllClaims(tempList);
            }
        })
        .catch(err => console.log(err));
    }

    const handleClaimDeclined = (claimID) => {
        const values = [claimID];
        axios.post('https://hrm.seaways-ship.com/updateClaimDeclined', {data: values})
        .then (res => {
            if (res.data.Status === "Error") console.log(res.data.Error);
            else {
                const tempList = [];
                for (var i = 0; i < allClaims.length; ++i) {
                    if (allClaims[i].id === claimID) {
                        continue;
                    } else {
                        tempList.push(allClaims[i]);
                    }
                }
                setAllClaims(tempList);
            }
        })
        .catch(err => console.log(err));
    } 

    const handleSettleClaim = (claimId, claimeeId, claimAmount) => {
        const values = [1, claimId];
        axios.post('https://hrm.seaways-ship.com/updateClaimSettle', {data: values})
        .then(res => {
            if (res.data.Status === "Error") console.log(res.data.Error);
            else {
                axios.get('https://hrm.seaways-ship.com/getTotalClaimed/' + claimeeId)
                .then(res => {
                    if (res.data.Status === "Success") {
                        const previousClaimed = res.data.Result[0].total_claimed;
                        const values = [previousClaimed + claimAmount, claimeeId];
                        axios.post('https://hrm.seaways-ship.com/updateTotalClaimed', {data: values})
                        .then(res => {
                            if (res.data.Status === "Error") console.log(res.data.Error);
                            else {
                                //Remove this claim from the list of claims in the pending to be settled table
                                const tempList = [];
                                for (var i = 0; i < allUnsettledClaims.length; ++i) {
                                    if (allUnsettledClaims[i].id === claimId) continue;
                                    else {
                                        tempList.push(allUnsettledClaims[i]);
                                    }
                                }
                                setAllUnsettledClaims(tempList);
                            }
                        })
                        .catch(err =>  console.log(err));
                    }
                })
                .catch(err => console.log(err));
            }
        })
        .catch(err => console.log(err));
    }

    const handleEditPayroll = (event) => {
        event.preventDefault();
        navigate('/editPayroll');
    }

    const handleAddPayroll = (event) => {
        event.preventDefault();
        navigate('/addPayroll');
    }

    const handleTabSwitch = (event) => {
        setSelectedTab(event.target.value);
    }

    return (
        <div className='mt-4'>
	    <div style={{textAlign: 'center'}}>
                <h5>Select the action you wish to perform from the list below</h5>
                <select onChange={handleTabSwitch}>
                    {permissions && permissions.includes('alter_leaves') && <option value="Leaves">Manage Leaves</option>}
                    {permissions && permissions.includes("alter_appraisal") && <option value="Key Appraisals">Key Appraisals</option>}
                    {permissions && permissions.includes("alter_claims") && <option value="Claims">Manage Claims</option>}
                    {permissions && permissions.includes("alter_payroll") && <option value="Payroll">Manage Payrolls</option>}
                    {permissions && permissions.includes("view_appraisal") && <option value="View Appraisals">View Appraisals</option>}
                </select>
            </div> <br />
            {selectedTab === "Leaves" && <div>
                <h4>Pending leave requests requiring approval</h4>
                {showAnnualLeaveErrorMsg && <p style={{color: 'red'}}>This employee does not have enough annual leaves</p>}
                <table CELLPADDING='6'>
                    <tr>
                        <th>Leave ID</th>
                        <th>Employee</th>
                        <th>Employee ID</th>
                        <th>Leave Type</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Approve</th>
                        <th>Decline</th>
                    </tr>
                        {leaveRequestList.map(data => {
                            return <tr>
                                <td>{data.id}</td>
                                <td>{data.employee_name}</td>
                                <td>{data.employee_id}</td>
                                <td>{data.leave_type}</td>
                                <td>{data.start_date}</td>
                                <td>{data.end_date}</td>
                                <td>
                                    <button onClick={() => handleApprove(data.id, data.employee_id, data.leave_type, data.start_date, data.end_date)}>
                                        Approve
                                    </button>
                                </td>
                                <td>
                                <button onClick={() => handleDecline(data.id)}>
                                        Decline
                                    </button>
                                </td>
                            </tr>;
                        })}
                </table>
                {leaveRequestList.length === 0 && <p style={{textAlign: 'center'}}>There are no requests</p>}
                <hr></hr>
            </div>}
            {selectedTab === "Key Appraisals" && <div>
                <h4>Pending key staff appraisals requiring HR review</h4>
                <p>Please go to the 'Claim Management' page to fill in the comments for the following forms</p>
                <table CELLPADDING='6'>
                    <tr>
                        <th>Appraisal ID</th>
                        <th>Appraisal Type</th>
                        <th>Appraisee</th>
                        <th>Supervisor</th>
                        <th>Department</th>
                        <th>Position</th>
                    </tr>
                    {keyAppraisalForHr.map(data => {
                        return (<tr>
                            <td>{data.appraisal_id}</td>
                            <td>Key Staff Appraisal</td>
                            <td>{data.employee_name}</td>
                            <td>{data.supervisor}</td>
                            <td>{data.department}</td>
                            <td>{data.position}</td>
                        </tr>)
                    })}
                </table>
                <hr></hr>
            </div>}
            {/* These 2 are only for hr manager */}
            {selectedTab === "Claims" && <div>
                <div style={{ display: 'flex' }}> 
                    <div style={{ width: '50%', marginRight: '10px' }}>
                        <h5>Pending claims requests requiring approval</h5>
                        <table CELLPADDING='4'>
                            <tr>
                                <th>Claim ID</th>
                                <th>Claimee Name</th>
                                <th>Amount</th>
                                <th>Reason</th>
                                <th>Remarks</th>
                                <th>Approve</th>
                                <th>Decline</th>
                            </tr>
                                {allClaims.map(data => {
                                    return <tr>
                                        <td>{data.id}</td>
                                        <td>{data.claimee_name}</td>
                                        <td>{data.claim_amount}</td>
                                        <td>{data.claim_reason}</td>
                                        <td>{data.claim_remarks}</td>
                                        <td><button onClick={() => handleClaimApproved(data.id)}>
                                            Approve
                                        </button></td>
                                        <td><button onClick={() => handleClaimDeclined(data.id)}>
                                            Decline
                                        </button></td>
                                    </tr>
                                })}
                        </table>
                        {allClaims.length === 0 && <p style={{textAlign: 'center'}}>There are no claim requests</p>}
                    </div>
                    <div style={{ width: '50%' }}>
                        <h5>Claim requests to be settled</h5>
                        <table CELLPADDING='4'>
                            <tr>
                                <th>Claim ID</th>
                                <th>Claimee Name</th>
                                <th>Amount</th>
                                <th>Reason</th>
                                <th>Remarks</th>
                                <th>Settle</th>
                            </tr>
                            {allUnsettledClaims.map(data => {
                                return <tr>
                                    <td>{data.id}</td>
                                    <td>{data.claimee_name}</td>
                                    <td>{data.claim_amount}</td>
                                    <td>{data.claim_reason}</td>
                                    <td>{data.claim_remarks}</td>
                                    <td><button onClick={(() => handleSettleClaim(data.id, data.claimee_id, data.claim_amount))}>
                                        Settle
                                    </button></td>
                                </tr>
                            })}
                        </table>
                    </div>
                </div>
                <hr></hr>
            </div>}
            {selectedTab === "Payroll" &&  
            <div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%', marginRight: '10px' }}>
                        <h4>Edit Employee Payroll</h4><br />
                        <button onClick={handleEditPayroll}>Click here to edit employee payroll information</button>
                    </div>
                    <div style={{ width: '50%' }}>
                        <h4>Add New Employee Payroll</h4><br />
                        <button onClick={handleAddPayroll}>Click here to add new employee payroll information</button>
                    </div>
                </div>
                <hr></hr>
            </div>}
            {selectedTab === "View Appraisals" && <div>
                <h4>View appraisal records for your employees</h4>
                <h5>Key appraisals</h5>
                <table CELLPADDING='6'>
                    <tr>
                        <th>Appraisal ID</th>
                        <th>Employee Name</th>
                        <th>Department</th>
                        <th>Position</th>
                        <th>Appraisal Period</th>
                        <th>Date of Appraisal</th>
                        <th>Appraisal Data</th>
                    </tr>
                    {keyAppraisalsList.map(data => {
                        return <tr>
                            <td>{data.appraisal_id}</td>
                            <td>{data.employee_name}</td>
                            <td>{data.department}</td>
                            <td>{data.position}</td>
                            <td>{data.appraisal_period}</td>
                            <td>{data.date_of_appraisal}</td>
                            <td>
                                <button onClick={() => handleKeyAppraisalData(data.appraisal_id)}>Click here</button>
                            </td>
                        </tr>
                    })}
                </table>
                {keyAppraisalsList.length === 0 && <p style={{textAlign: 'center'}}>There are no key employee appraisals</p>}
                <hr></hr>
                <h5>Self appraisals</h5>
                <table CELLPADDING='6'>
                    <tr>
                        <th>Appraisal ID</th>
                        <th>Employee Name</th>
                        <th>Department</th>
                        <th>Position</th>
                        <th>Commencement Date</th>
                        <th>Evaluation Date</th>
                        <th>Appraisal Data</th>
                        <th>Add comments</th>
                    </tr>
                    {selfAppraisalsList.map(data => {
                        return <tr>
                            <td>{data.appraisal_id}</td>
                            <td>{data.employee_name}</td>
                            <td>{data.department}</td>
                            <td>{data.position}</td>
                            <td>{data.commencement_date}</td>
                            <td>{data.evaluation_date}</td>
                            <td>
                                <button onClick={() => handleSelfAppraisalData(data.appraisal_id)}>Click here</button>
                            </td>
                            {permissions && permissions.includes("alter_claims") ? <td>
                                <input type="text" 
                                    onChange={(event) => setSelfAppraisalComments([...selfAppraisalComments.slice(0,1), event.target.value])}
                                    onKeyDown={(event) => handleButtonPress(event, data.appraisal_id)}
                                ></input>
                            </td> : <td>
                                <input type="text" 
                                    onChange={(event) => setSelfAppraisalComments([event.target.value, ...selfAppraisalComments.slice(1,2)])}
                                    onKeyDown={(event) => handleButtonPress(event, data.appraisal_id)}
                                ></input>
                            </td>
                            }
                        </tr>
                    })}
                </table>
                {selfAppraisalsList.length === 0 && <p style={{textAlign: 'center'}}>There are no self appraisals</p>}
            </div>}
            {showKeyAppraisalData && 
                <div className='popupAppraisalOverlay'>
                    <div className='popupAppraisalContainer'>
                        <div className='popupAppraisalMessage'>
                        <h5>Section I: Goal Setting By Supervisor</h5>
                        <h6>Following Annual Goals have been set for the employee for the current year:</h6>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Standard aspects</th>
                                <th>Measurable Goals/Targets</th>
                                <th>Year End Assessment for each goal</th>
                            </tr>
                            <tr>
                                <td>1. Customer Satisfaction Survey Score</td>
                                <td>{keyAppraisalToShow.s1Target.slice(0,1)}</td>
                                <td>{keyAppraisalToShow.s1Rating.slice(0,1)}</td>
                            </tr>
                            <tr>
                                <td>2. Audit Performance</td>
                                <td>{keyAppraisalToShow.s1Target.slice(3,4)}</td>
                                <td>{keyAppraisalToShow.s1Rating.slice(3,4)}</td>
                            </tr>
                            <tr>
                                <td>3. PSC Performance</td>
                                <td>{keyAppraisalToShow.s1Target.slice(6,7)}</td>
                                <td>{keyAppraisalToShow.s1Rating.slice(6,7)}</td>
                            </tr>
                            <tr>
                                <td>4. Ship/Shore Staff Retention</td>
                                <td>{keyAppraisalToShow.s1Target.slice(9,10)}</td>
                                <td>{keyAppraisalToShow.s1Rating.slice(9,10)}</td>
                            </tr>
                            <tr>
                                <td>5. Personal Safety (LTIF, TRCF, Injuries, Fatalities</td>
                                <td>{keyAppraisalToShow.s1Target.slice(12,13)}</td>
                                <td>{keyAppraisalToShow.s1Rating.slice(12,13)}</td>
                            </tr>
                            <tr>
                                <td>6. Near Misses</td>
                                <td>{keyAppraisalToShow.s1Target.slice(15,16)}</td>
                                <td>{keyAppraisalToShow.s1Rating.slice(15,16)}</td>
                            </tr>
                            <tr>
                                <td>7. Best Practices</td>
                                <td>{keyAppraisalToShow.s1Target.slice(18,19)}</td>
                                <td>{keyAppraisalToShow.s1Rating.slice(18,19)}</td>
                            </tr>
                            <tr>
                                <td>8. Vessel Off-Hire</td>
                                <td>{keyAppraisalToShow.s1Target.slice(21,22)}</td>
                                <td>{keyAppraisalToShow.s1Rating.slice(21,22)}</td>
                            </tr>
                            <tr>
                                <td>9. PMS/ Breakdowns</td>
                                <td>{keyAppraisalToShow.s1Target.slice(24,25)}</td>
                                <td>{keyAppraisalToShow.s1Rating.slice(24,25)}</td>
                            </tr>
                            <tr>
                                <td>10. Environment Objectives</td>
                                <td>{keyAppraisalToShow.s1Target.slice(27,28)}</td>
                                <td>{keyAppraisalToShow.s1Rating.slice(27,28)}</td>
                            </tr>
                        </table>
                        <br />
                        <hr></hr>
                        <h5>Section II: Performance of Key Job Duties</h5>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Instruction</th>
                                <th>Employee</th>
                                <th>Supervisor</th>
                            </tr>
                            <tr>
                                <td>Rate your performance on your job duties assigned</td>
                                <td>{keyAppraisalToShow.s2Rating.slice(0,1)}</td>
                                <td>{keyAppraisalToShow.s2Rating.slice(3)}</td>   
                            </tr>
                            <tr>
                                <td>Comments:</td>
                                <td>{keyAppraisalToShow.s2Comments.split(';')[0]}</td>
                                <td>{keyAppraisalToShow.s2Comments.split(';')[1]}</td>                            
                            </tr>
                        </table>
                        <br />
                        <hr></hr>
                        <h5>Section III: General Work Performance</h5>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Factor</th>
                                <th>Employee</th>
                                <th>Supervisor</th>
                                <th>HR</th>
                            </tr>
                            <tr>
                                <td><strong>1. QUANTITY OF WORK (7%):</strong>
The extent to which the employee accomplishes assigned work of a
specified quality within a specified time period.</td>
                                <td>{keyAppraisalToShow.s3EmployeeRating.split(', ')[0]}</td>
                                <td>{keyAppraisalToShow.s3SupervisorRating.split(', ')[0]}</td>
                                <td>{keyAppraisalToShow.s3HrRating.split(', ')[0]}</td>
                            </tr>
                            <tr>
                                <td><strong>2. QUALITY OF WORK (15%)</strong>
Consider accuracy, thoroughness, effectiveness.</td>
                                <td>{keyAppraisalToShow.s3EmployeeRating.split(',')[1]}</td>
                                <td>{keyAppraisalToShow.s3SupervisorRating.split(',')[1]}</td>
                                <td>{keyAppraisalToShow.s3HrRating.split(',')[1]}</td>
                            </tr>
                            <tr>
                                <td><strong>3. KNOWLEDGE OF JOB (15%)</strong>
The extent to which the employee demonstrates how and why to do all
phases of assigned work, given the employee&#39;s length of time in his/her
current position.</td>
                                <td>{keyAppraisalToShow.s3EmployeeRating.split(',')[2]}</td>
                                <td>{keyAppraisalToShow.s3SupervisorRating.split(',')[2]}</td>
                                <td>{keyAppraisalToShow.s3HrRating.split(',')[2]}</td>
                            </tr>
                            <tr>
                                <td><strong>4. FLEXIBILITY (7%)</strong>
Consider performance under pressure and handling of multiple
assignments.</td>
                                <td>{keyAppraisalToShow.s3EmployeeRating.split(',')[3]}</td>
                                <td>{keyAppraisalToShow.s3SupervisorRating.split(',')[3]}</td>
                                <td>{keyAppraisalToShow.s3HrRating.split(',')[3]}</td>
                            </tr>
                            <tr>
                                <td><strong>5. INITIATIVE (8%)</strong>
Consider the extent to which the employee sets own constructive work
practice and recommends and creates own procedures.</td>
                                <td>{keyAppraisalToShow.s3EmployeeRating.split(',')[4]}</td>
                                <td>{keyAppraisalToShow.s3SupervisorRating.split(',')[4]}</td>
                                <td>{keyAppraisalToShow.s3HrRating.split(',')[4]}</td>
                            </tr>
                            <tr>
                                <td><strong>6. DEPENDABILITY (10%)</strong>
Consider the extent to which the employee completes assignments on
time and carries out instructions.</td>
                                <td>{keyAppraisalToShow.s3EmployeeRating.split(',')[5]}</td>
                                <td>{keyAppraisalToShow.s3SupervisorRating.split(',')[5]}</td>
                                <td>{keyAppraisalToShow.s3HrRating.split(',')[5]}</td>
                            </tr>
                            <tr>
                                <td><strong>7. INTERPERSONAL RELATIONS (10%)</strong>
Consider the extent to which the employee is cooperative, considerate,
and tactful in dealing with supervisors, subordinates, colleagues and
others and is willing to accept constructive criticism.</td>
                                <td>{keyAppraisalToShow.s3EmployeeRating.split(',')[6]}</td>
                                <td>{keyAppraisalToShow.s3SupervisorRating.split(',')[6]}</td>
                                <td>{keyAppraisalToShow.s3HrRating.split(',')[6]}</td>
                            </tr>
                            <tr>
                                <td><strong>8. ADHERENCE TO COMPANY ALL POLICIES &amp; PROCEDURES (10%)</strong>
Adherence to policies and procedures</td>
<td>{keyAppraisalToShow.s3EmployeeRating.split(',')[7]}</td>
                                <td>{keyAppraisalToShow.s3SupervisorRating.split(',')[7]}</td>
                                <td>{keyAppraisalToShow.s3HrRating.split(',')[7]}</td>
                            </tr>
                            <tr>
                                <td><strong>9. ORGANIZATION (8%)</strong>
The extent of projects is well conceived, analyzed, and carried out
systematically?</td>
<td>{keyAppraisalToShow.s3EmployeeRating.split(',')[8]}</td>
                                <td>{keyAppraisalToShow.s3SupervisorRating.split(',')[8]}</td>
                                <td>{keyAppraisalToShow.s3HrRating.split(',')[8]}</td>
                            </tr>
                            <tr>
                                <td><strong>10. PROBLEM SOLVING SKILL (10%)</strong></td>
                                <td>{keyAppraisalToShow.s3EmployeeRating.split(',')[9]}</td>
                                <td>{keyAppraisalToShow.s3SupervisorRating.split(',')[9]}</td>
                                <td>{keyAppraisalToShow.s3HrRating.split(',')[9]}</td>
                            </tr>
                        </table>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Employee's comments</th>
                                <th>Supervisor's comments</th>
                            </tr>
                            <tr>
                                <td>{keyAppraisalToShow.s3Comments.split(';')[0]}</td>
                                <td>{keyAppraisalToShow.s3Comments.split(';')[1]}</td>
                            </tr>
                        </table>
                        <br />
                        <hr></hr>
                        <h5>Section IV: Management Performance Factor (For Supervisor Grade Only)</h5>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Metric</th>
                                <th>Employee</th>
                                <th>Supervisor</th>
                                <th>HR</th>
                            </tr>
                            <tr>
                                <td><strong>1. INNOVATION (20%)</strong>
The extent to which the individual provides guidance and opportunities
to staff members for their development and advancement in the
Company.</td>
                                <td>{keyAppraisalToShow.s4EmployeeRating.split(',')[0]}</td>
                                <td>{keyAppraisalToShow.s4SupervisorRating.split(',')[0]}</td>
                                <td>{keyAppraisalToShow.s4HrRating.split(',')[0]}</td>
                            </tr>
                            <tr>
                                <td><strong>2. DELEGATION (20%)</strong>
The extent to which the supervisor delegate work to team members
clearly and monitor those work regularly and effectively.</td>
                                <td>{keyAppraisalToShow.s4EmployeeRating.split(',')[1]}</td>
                                <td>{keyAppraisalToShow.s4SupervisorRating.split(',')[1]}</td>
                                <td>{keyAppraisalToShow.s4HrRating.split(',')[1]}</td>
                            </tr>
                            <tr>
                                <td><strong>3. LEADERSHIP (30%)</strong>
The extent to which the supervisor provides clear direction, instruction
and goal to team members and dedicates considerable effort to
develop the skill of team members. The Supervisor shows strong
leadership and confidence.</td>
                                <td>{keyAppraisalToShow.s4EmployeeRating.split(',')[2]}</td>
                                <td>{keyAppraisalToShow.s4SupervisorRating.split(',')[2]}</td>
                                <td>{keyAppraisalToShow.s4HrRating.split(',')[2]}</td>   
                            </tr>
                            <tr>
                                <td><strong>4. DECISION MAKING (30%)</strong>
Be able to weight the positives and negatives of each option among all
alternatives when making decision. Be able to forecast the outcome of
each option and base on all of those items, determine which option is
the best of that particular situation.</td>
                                <td>{keyAppraisalToShow.s4EmployeeRating.split(',')[3]}</td>
                                <td>{keyAppraisalToShow.s4SupervisorRating.split(',')[3]}</td>
                                <td>{keyAppraisalToShow.s4HrRating.split(',')[3]}</td>   
                            </tr>
                        </table>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Employee's comments</th>
                                <th>Supervisor's comments</th>
                            </tr>
                            <tr>
                                <td>{keyAppraisalToShow.s4Comments.split(';')[0]}</td>
                                <td>{keyAppraisalToShow.s4Comments.split(';')[1]}</td>
                            </tr>
                        </table>
                        <br />
                        <hr></hr>
                        <h5>Section V: Achievement of Annual Goals Set for the Employee</h5>
                        <table>
                            <tr>
                                <td>Part A) Employee’s Final Comments Including any specific training &amp; Career Development Requests:</td>
                                <td>{keyAppraisalToShow.s5Comments.split(';')[0]}</td>
                            </tr>
                            <tr>
                                <td>Part B) Supervisor’s Final Comments including any Identified Training Needs &amp; Effectiveness of the
training carried out during the assessment period.</td>
                                <td>{keyAppraisalToShow.s5Comments.split(';')[1]}</td>
                            </tr>
                        </table>
                        <div style={{textAlign: 'center'}}>
                            <button onClick={handleKeyClose}>Close</button>
                        </div>
                </div>
                        </div>
                    </div>
                }
                {showSelfAppraisalData &&
                <div className='popupAppraisalOverlay'>
                    <div className='popupAppraisalContainer'>
                        <div className='popupAppraisalMessage'>
                        <h4 style={{textAlign: 'center'}}>CONTRIBUTIONS</h4>
                    <table CELLPADDING='6'>
                        <tr>
                            <th>Begin your self-assessment by describing your accomplishments against the goals identified in your previous year’s appraisal.</th>
                        </tr>
                        <tr>
                            <td>{selfAppraisalToShow.response1}</td>
                        </tr>
                        <tr>
                            <th>Cite examples of successful accomplishments since your last review (assignments completed, projects, presentations, etc.). Identify any significant results.</th>
                        </tr>
                        <tr>
                            <td>{selfAppraisalToShow.response2}</td>                        
                        </tr>
                        <tr>
                            <th>If applicable, identify responsibilities that may have been added or removed from your job during this review period.</th>
                        </tr>
                        <tr>
                            <td>{selfAppraisalToShow.response3}</td>                        
                        </tr>
                        <tr>
                            <th>What specific goals would you like to accomplish in the year ahead?</th>
                        </tr>
                        <tr>
                            <td>{selfAppraisalToShow.response4}</td>                        
                        </tr>
                        <tr>
                            <th>Did you participate in team or committee activities? If appropriate, identify outcomes or results.</th>
                        </tr>
                        <tr>
                            <td>{selfAppraisalToShow.response5}</td>                        
                        </tr>
                    </table>
                    <hr></hr>
                    <h4 style={{textAlign: 'center'}}>OPPORTUNITIES</h4>
                    <table CELLPADDING='6'>
                        <tr>
                            <th>List any initiatives you would like to have completed this year but were unable to? Why?</th>
                        </tr>
                        <tr>
                            <td>{selfAppraisalToShow.response6}</td>                        
                        </tr>
                        <tr>
                            <th>Identify any obstacles that hindered you from effectively accomplishing your work.</th>
                        </tr>
                        <tr>
                            <td>{selfAppraisalToShow.response7}</td>                        
                        </tr>
                        <tr>
                            <th>In what areas and from whom do you feel that you need added support or direction.</th>
                        </tr>
                        <tr>
                            <td>{selfAppraisalToShow.response8}</td>                        
                        </tr>
                    </table>
                    <hr></hr>
                    <h4 style={{textAlign: 'center'}}>TRAINING AND PROFESSIONAL DEVELOPMENT</h4>
                    <table CELLPADDING='6'>
                        <tr>
                            <th>List any training programs or professional development you completed this past year. (seminars, on-site training classes, challenging projects, on-the-job experiences)</th>
                        </tr>
                        <tr>
                            <td>{selfAppraisalToShow.response9}</td>                        
                        </tr>
                        <tr>
                            <th>Identify skills/knowledge which could increase your performance effectiveness and any other related training/learning opportunities.</th>
                        </tr>
                        <tr>
                            <td>{selfAppraisalToShow.response10}</td>                        
                        </tr>
                        <tr>
                            <th>Identify professional conferences meetings professional associations or certifications in which your participation would aid in your professional development.</th>
                        </tr>
                        <tr>
                            <td>{selfAppraisalToShow.response11}</td>                        
                        </tr>
                    </table>
                    <hr></hr>
                    <h4 style={{textAlign: 'center'}}>PERFORMANCE APPRAISAL DISCUSSION</h4>
                    <table CELLPADDING='6'>
                        <tr>
                            <th>List any subjects you may want to discuss at the appraisal meeting with your supervisor.</th>
                        </tr>
                        <tr>
                            <td>{selfAppraisalToShow.response12}</td>                        
                        </tr>
                        <tr>
                            <th>What do you need from your supervisor to do an even better job?</th>
                        </tr>
                        <tr>
                            <td>{selfAppraisalToShow.response13}</td>                        
                        </tr>
                        <tr>
                            <th>What individuals, including project team leaders or members, clients or customers, and colleagues, would you like to provide to your supervisor who could comment or provide feedback regarding your performance?</th>
                        </tr>
                        <tr>
                            <td>{selfAppraisalToShow.response14}</td>                        
                        </tr>
                    </table>
                        <div style={{textAlign: 'center'}}>
                            <button onClick={handleSelfClose} style={{textAlign: 'center'}}>Close</button>
                        </div>
                        </div>
                    </div>
                </div>
                }
        </div>
    )
}

export default Manage;
