import {legacy_createStore as createStore} from 'redux';

const employeeid = 0;
const employeeIdReducer = (state = employeeid, action) => {
    switch (action.type) {
      case 'alter':
        return action.payload;
      default:
        return state;
    }
  }
export const store = createStore(employeeIdReducer);

export function alterEmployeeId(id) {
    return {
        type: 'alter',
        payload: id
    }
}