import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {useSelector} from 'react-redux';
import { selectEmployeeId } from '../Slice/SelectEmployeeId';
import '../style.css';

function Payroll() {
    const [payrollData, setPayrollData] = useState({
        id: 0,
        salary: 0,
        double_pay: 0,
        bonus: 0,
        special: 0,
        leave_pay: 0,
        total_pay: 0,
        mpf_mandatory_contrib: 0,
        mpf_voluntary_contrib: 0,
        others: 0,
        net_pay: 0,
        total_deductions: 0
    });
    const [employeeId, setEmployeeId] = useState(useSelector(selectEmployeeId));

    const navigate = useNavigate();

    useEffect(() => {
        // console.log(employeeId)
        axios.get('https://hrm.seaways-ship.com/getPayroll/' + employeeId)
        .then(res => {
            if (res.data.Status == "Success") {
                console.log(res.data.Result);
                setPayrollData(res.data.Result[0]);
            }
        })
        .catch(err => console.log(err));
    }, [])

    const handlePreviousPayroll = () => {
        navigate('/historicalPayroll');
    }

    return (
        <div className='mt-5'>
            <div className='payrollDiv'>
                <div class="col p-0 m-0">
                        <div className='p-2 d-flex justify-content-center'>
                            <h4>Current Payroll Details</h4>						
                        </div>
                    </div>
                {payrollData && 
                <div>
                    <table className='payTable'>
                        <tr>
                            <th><h3><strong><u>Income</u></strong></h3></th>
                            <th><h3><strong><u>Amount</u></strong></h3></th>
                        </tr>
                        <tr>
                            <td>Salary</td>
                            <td>${payrollData.salary}</td>
                        </tr>
                        <tr>
                            <td>Double pay</td>
                            <td>${payrollData.double_pay}</td>
                        </tr>
                        <tr>
                            <td>Bonus</td>
                            <td>${payrollData.bonus}</td>
                        </tr>
                        <tr>
                            <td>Special</td>
                            <td>${payrollData.special}</td>
                        </tr>
                        <tr>
                            <td>Leave pay</td>
                            <td>${payrollData.leave_pay}</td>
                        </tr>
                        <tr>
                            <td><strong>Total pay</strong></td>
                            <td><strong>${payrollData.total_pay}</strong></td>
                        </tr>
                        <br />
                        <tr>
                            <th><h3><strong><u>Deductions</u></strong></h3></th>
                            <th><h3><strong><u>Amount</u></strong></h3></th>
                        </tr>
                        <tr>
                            <td>MPF mandatory contribution</td>
                            <td>${payrollData.mpf_mandatory_contrib}</td>
                        </tr>
                        <tr>
                            <td>MPF voluntary contribution</td>
                            <td>${payrollData.mpf_voluntary_contrib}</td>
                        </tr>
                        <tr>
                            <td>Others</td>
                            <td>${payrollData.others}</td>
                        </tr>
                        <br />
                        <tr>
                            <th><h5><strong>Total Deductions</strong></h5></th>
                            <td>${payrollData.total_deductions}</td>
                        </tr>
                        <tr>
                        <th><h5><strong><u>Net Pay</u></strong></h5></th>
                        <td>${payrollData.net_pay}</td>
                        </tr>
                    </table>
                </div>}
                <br />
            </div>
            <div className='d-flex justify-content-center align-items-center mt-3' onClick={handlePreviousPayroll}>
                <button>View your past payrolls</button>
            </div>
        </div>
    )
}

export default Payroll;
