import React, {useState, useEffect} from 'react';
import axios from 'axios';
import '../style.css';
import { useNavigate } from 'react-router-dom';

/*
To do for this page:
- Change logic for key employee appraisal 
*/

function CreateAppraisal() {
    const [appraisalType, setAppraisalType] = useState("Support");
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    //States that are common among forms
    const [employeeName, setEmployeeName] = useState();
    const [department, setDepartment] = useState();
    const [position, setPosition] = useState();
    const [commencementDate, setCommencementDate] = useState();
    const [evaluationDate, setEvaluationDate] = useState();
    const [supervisor, setSupervisor] = useState();
    const [appraisalId, setAppraisalId] = useState(0);
    const [supervisorID, setSupervisorID] = useState();
    //States for support staff apraisal
    const [designation, setDesignation] = useState();
    const [periodFrom, setPeriodFrom] = useState();
    const [periodTo, setPeriodTo] = useState();
    const [appraiserName, setAprraiserName] = useState();
    const [s1q1Target, sets1q1Target] = useState();
    const [s1q1Rating, sets1q1Rating] = useState();
    const [s1q2Target, sets1q2Target] = useState();
    const [s1q2Rating, sets1q2Rating] = useState();
    const [s2Ratings, setS2Ratings] = useState([0,0,0,0,0,0,0,0,0,0]);
    const [appraiserComment, setAprraiserComment] = useState();
    const [employeeComment, setEmployeeComment] = useState();
    //States for key office staff appraisal
    const [appraiserPosition, setAppraiserPosition] = useState("Employee");
    const [employeeID, setEmployeeID] = useState();
    const [appraisalPeriod, setAppraisalPeriod] = useState(['','']);
    const [dateOfAppraisal, setDateOfAppraisal] = useState();
    const [form2s1Target, setForm2s1Target] = useState([0,0,0,0,0,0,0,0,0,0]);
    const [form2s1Rating, setForm2s1Rating] = useState([0,0,0,0,0,0,0,0,0,0]);
    const [form2s2Rating, setForm2s2Rating] = useState([0,0]);
    const [form2s2Comments, setForm2s2Comments] = useState(['','']);
    const [form2s3EmployeeRatings, setForm2s3EmployeeRatings] = useState([0,0,0,0,0,0,0,0,0,0]);
    const [form2s3SupervisorRatings, setForm2s3SupervisorRatings] = useState([0,0,0,0,0,0,0,0,0,0]);
    const [form2s3HrRatings, setForm2s3HrRatings] = useState([0,0,0,0,0,0,0,0,0,0]);
    const [form2s3Comments, setForm2s3Comments] = useState(['','']);
    const [form2s4Employee, setForm2s4Employee] = useState([0,0,0,0]);
    const [form2s4Supervisor, setForm2s4Supervisor] = useState([0,0,0,0]);
    const [form2s4Hr, setForm2s4Hr] = useState([0,0,0,0]);
    const [form2s4Comments ,setForm2s4Comments] = useState(['','']);
    const [form2s5Comments, setForm2s5Comments] = useState(['','']);
    //States for probitionary employee appraisal
    const [lastDayProbation, setLastDayProbation] = useState();
    const [form3s1Rating, setForm3s1Rating] = useState([0,0,0,0,0,0,0,0]);
    const [form3s1Comments, setForm3s1Comments] = useState(['','']);
    const [form3s1Checkboxes, setForm3s1Checkboxes] = useState([0,0,0,0]);
    //States for self appraisal
    const [form4Responses, setForm4Responses] = useState(['','','','','','','','','','','','','','']);

    const navigate = useNavigate();

    useEffect(() => {
        console.log(periodFrom);
    })

    const handleAppraisalChange = (event) => {
        setAppraisalType(event.target.value);
    }

    const onClose = () => {
        setShowSuccessMsg(false);
    }

    const handleSupportSubmit = (event) => {
        event.preventDefault();
        const combinedS2Ratings = s2Ratings.join(', ')
        const values = [
            Math.floor((Math.random() * 1000000) + 1),
            employeeName, designation, department, position, periodFrom, periodTo, appraiserName, s1q1Target,
            s1q1Rating, s1q2Target, s1q2Rating, combinedS2Ratings, appraiserComment, employeeComment];
        axios.post('https://hrm.seaways-ship.com/createSupportAppraisal', {data: values})
        .then(res => {
            if (res.data.Status === "Error") console.log(res.data.Error);
            else {
                setShowSuccessMsg(true);
                const inputs = document.getElementsByTagName("input");
                for (let i = 0; i < inputs.length; i++) {
                    inputs[i].value = "";   
                }
            }
        })
        .catch(err => console.log(err));
    }

    const handleKeySubmit = (event) => {
        event.preventDefault();
	    if (appraisalId === 0) setAppraisalId(Math.floor((Math.random() * 1000000) + 1));
	console.log("Appraisal ID is: ", appraisalId);
        axios.get('https://hrm.seaways-ship.com/getExisitingKeyAppraisal/' + appraisalId)
        .then(res => {
	    console.log(res.data.Result.length);
            if (res.data.Result.length === 0) {
                const values = [
                    appraisalId, employeeName, employeeID, supervisorID, department, position, supervisor, commencementDate, appraisalPeriod.join(' to '), dateOfAppraisal, 
                    form2s1Target.join(', '), form2s1Rating.join(', '), form2s2Rating.join(', '), form2s2Comments.join('; '), form2s3EmployeeRatings.join(', '), form2s3SupervisorRatings.join(', '),
                    form2s3HrRatings.join(', '), form2s3Comments.join('; '), form2s4Employee.join(', '), form2s4Supervisor.join(', '), form2s4Hr.join(', '), form2s4Comments.join('; '), form2s5Comments.join('; '),
                    appraiserPosition
                ];
                axios.post('https://hrm.seaways-ship.com/createKeyAppraisal', {data: values})
                .then(res => {
                    if (res.data.Status === "Error") console.log(res.data.Error);
                    else {
                        setShowSuccessMsg(true);
                        const inputs = document.getElementsByTagName("input");
                        for (let i = 0; i < inputs.length; i++) {
                            inputs[i].value = "";   
                        }
                    }
                })
                .catch(err => console.log(err));
            } else if (res.data.Result.length !== 0) {
                const result = res.data.Result[0];
                console.log(result);
                const objValues = result;
                console.log(objValues); 
                if (appraiserPosition === "Employee") {
                    objValues['s2Comments'] = form2s2Comments[0] + ";" + objValues['s2Comments'].split(';')[1];
                    objValues['s2Rating'] = form2s2Rating[0].toString() + ',' + objValues['s2Rating'].split(',')[1];
                    objValues['s3Comments'] = form2s3Comments[0] + ";" + objValues['s3Comments'].split(';')[1];
                    objValues['s3EmployeeRating'] = form2s3EmployeeRatings.join(', ');
                    objValues['s4Comments'] = form2s4Comments[0] + ";" + objValues['s4Comments'].split(';')[1];
                    objValues['s4EmployeeRating'] = form2s4Employee.join(', ');
                    objValues['s5Comments'] = form2s5Comments[0] + ";" + objValues['s5Comments'].split(';')[1];
                } else if (appraiserPosition === "Supervisor") {
                    objValues['s1Rating'] = form2s1Rating.join(', ');
                    objValues['s1Target'] = form2s1Target.join(', ');
                    objValues['s2Comments'] = objValues['s2Comments'].split(';')[0] + ";" + form2s2Comments[1];
                    objValues['s2Rating'] = objValues['s2Rating'].split(',')[0] + ',' + form2s2Rating[1].toString();
                    objValues['s3Comments'] = objValues['s3Comments'].split(';')[0] + ";" + form2s3Comments[1];
                    objValues['s3SupervisorRating'] = form2s3SupervisorRatings.join(', ');
                    objValues['s4Comments'] = objValues['s4Comments'].split(';')[0] + ";" + form2s4Comments[1];
                    objValues['s4SupervisorRating'] = form2s4Supervisor.join(', ');
                    objValues['s5Rating'] = objValues['s5Comments'].split(';')[0] + ";" + form2s5Comments[1];
                } else {
                    objValues['s3HrRating'] = form2s3HrRatings.join(', ');
                    objValues['s4HrRating'] = form2s4Hr.join(', ');
                }
                const values = [objValues.s1Rating, objValues.s1Target, objValues.s2Comments,
                                objValues.s2Rating, objValues.s3Comments, objValues.s3EmployeeRating,
                            objValues.s3HrRating, objValues.s3SupervisorRating, objValues.s4Comments, 
                        objValues.s4EmployeeRating, objValues.s4HrRating, objValues.s4SupervisorRating,
                    objValues.s5Comments, appraisalId];
                console.log(objValues);
                console.log(values);
                axios.post('https://hrm.seaways-ship.com/updateKeyAppraisal', {data: values})
                .then(res => {
		    if (res.data.Status === "Success") {
                        setShowSuccessMsg(true);
                    }
                    console.log(res.data.Status);
                })
                .catch(err => console.log(err));
            }
        })
        .catch(err => console.log(err));
    }

    const handleProbitionarySubmit = (event) => {
        event.preventDefault();
        const values = [
            Math.floor((Math.random() * 1000000) + 1),
            employeeName, department, position, evaluationDate, lastDayProbation, commencementDate, form3s1Rating.join(', '), form3s1Comments.join('; '),
            form3s1Checkboxes.join(', ')
        ];
        axios.post('https://hrm.seaways-ship.com/createProbitionaryAppraisal', {data: values})
        .then(res => {
            if (res.data.Status === "Error") console.log(res.data.Error);
            else {
                setShowSuccessMsg(true);
                const inputs = document.getElementsByTagName("input");
                for (let i = 0; i < inputs.length; i++) {
                    inputs[i].value = "";   
                }
            }
        })
        .catch(err => console.log(err));
    }

    const handleSelfSubmit = (event) => {
        event.preventDefault();
        const values = [
            Math.floor((Math.random() * 1000000) + 1),
            employeeName, department, position, supervisor, commencementDate, evaluationDate, form4Responses[0],
            form4Responses[1], form4Responses[2], form4Responses[3], form4Responses[4], form4Responses[5], form4Responses[6],
            form4Responses[7], form4Responses[8], form4Responses[9], form4Responses[10], form4Responses[11], form4Responses[12],
            form4Responses[13], supervisorID
        ];
        axios.post('https://hrm.seaways-ship.com/createSelfAppraisal', {data: values})
        .then(res => {
            if (res.data.Status === "Error") console.log(res.data.Error);
            else {
                setShowSuccessMsg(true);
                const inputs = document.getElementsByTagName("input");
                for (let i = 0; i < inputs.length; i++) {
                    inputs[i].value = "";   
                }
            }
        })
        .catch(err => console.log(err));
    }

    const handleReturn = () => {
        navigate('/appraisal');
    }

    return (
        <div>
            <div style={{position: 'relative', top: '-50px', right: '-10px'}}>
                <button style={{backgroundColor: 'transparent', border: 'none', color: '#000'}} onClick={handleReturn}>
                    <i className='bi bi-arrow-left-circle-fill' style={{fontSize: '30px'}}></i>
                </button>
            </div>
            <form style={{position: 'relative', top: '-40px'}}>
            <label for="type" className='mt-3'><h4>Please select the type of appraisal report:</h4></label> <br />
            <select id="type" name="appraisal type" onChange={handleAppraisalChange} className='mb-3'>
                    <option value="Support">Support staff appraisal</option>
                    <option value="Key">Key office staff appraisal</option>
                    <option value="Probitionary">Probitionary employee appraisal</option>
                    <option value="Self">Self appraisal</option>
            </select> <br />
            <hr></hr>
            <div className='m-3'>
                        <p>
                            PERFORMANCE RATINGS SCALE: Using the following definitions, evaluate employee’s performance by
                            writing a number between 1 and 5 for <strong>section's which require a numerical rating.</strong> 
                        </p>
                        <p>
                        <strong>5 = Excellent</strong>: Contributions and excellent work are widely recognized. Performance consistently
                            exceeds all defined expectations, producing important and impactful results through superior
                            planning, executing, and creativity.
                        </p>
                        <p>
                        <strong>4 = Outstanding</strong>: Most performance objectives exceed expectations. Projects and objectives are
                            completed in a manner that expands the scope and impact of the assignment and increases the
                            impact on the business.
                        </p>
                        <p>
                        <strong>3 = Satisfactory</strong>: Performance is competent and effective along established expectations, initiative,
                            resourcefulness. Performance generally meets standards.
                        </p>
                        <p>
                        <strong>2 = Improvement required</strong>: Performance falls below expectations on one or more job requirements
                            and responsibilities.
                        </p>
                        <p>
                        <strong>1 = Unacceptable</strong>: Performance falls far below expectations on most job requirements and responsibilities.
                        </p>
            </div>
            <hr></hr>
            {
                appraisalType === "Support" &&
                <div>
                    <form>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Employee Name</th>
                                <td><input onChange={(event) => setEmployeeName(event.target.value)}></input></td>
                                <th>Designation</th>
                                <td><input onChange={(event) => setDesignation(event.target.value)}></input></td>
                            </tr>
                            <tr>
                                <th>Department</th>
                                <td><input onChange={(event) => setDepartment(event.target.value)}></input></td>
                                <th>Position</th>
                                <td><input onChange={(event) => setPosition(event.target.value)}></input></td>
                            </tr>
                            <tr>
                                <th>Appraisal period from</th>
                                <td><input type="date" onChange={(event) => setPeriodFrom(event.target.value)}></input></td>
                                <th>To</th>
                                <td><input type="date" onChange={(event) => setPeriodTo(event.target.value)}></input></td>
                            </tr>
                            <tr>
                                <th>Name of Appraiser</th>
                                <td><input onChange={(event) => setAprraiserName(event.target.value)}></input></td>
                            </tr>
                        </table>
                        <hr></hr>
                        <br />
                        <h5>Section I: Goal Setting By Supervisor</h5>
                        <h6>Following Annual Goals have been set for the employee for the current year:</h6>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Standard aspects</th>
                                <th>Measurable Goals/Targets</th>
                                <th>Year End Assesment Rating</th>
                            </tr>
                            <tr>
                                <td>Personal Safety</td>
                                <td><input type="number" min="1" max="5" onChange={(event) => sets1q1Target(event.target.value)}></input></td>
                                <td><input input type="number" min="1" max="5" onChange={(event) => sets1q1Rating(event.target.value)}></input></td>
                            </tr>
                            <tr>
                                <td>Environmental objectives</td>
                                <td><input input type="number" min="1" max="5" onChange={(event) => sets1q2Target(event.target.value)}></input></td>
                                <td><input input type="number" min="1" max="5" onChange={(event) => sets1q2Rating(event.target.value)}></input></td>
                            </tr>
                        </table>
                        <hr></hr>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>S.no</th>
                                <th>Item</th>
                                <th>Rating</th>
                            </tr>
                            <tr>
                                <td>1.</td>
                                <td>Professional Knowledge and skills</td>
                                <td>
                                    <input type="range" min="1" max="5" value={s2Ratings[0]} onChange={(event) => setS2Ratings([event.target.value, ...s2Ratings.slice(1,10)])} />
                                </td>
                            </tr>
                            <tr>
                                <td>2.</td>
                                <td>Performance of Duties: Performance against designated job responsibilties</td>
                                <td>
                                    <input type="range" min="1" max="5" value={s2Ratings[1]} onChange={(event) => setS2Ratings([...s2Ratings.slice(0,1), event.target.value, ...s2Ratings.slice(2,10)])} />
                                </td>
                            </tr>
                            <tr>
                                <td>3.</td>
                                <td>General Conduct: Appearance, manners , punctuality and obedience.</td>
                                <td>
                                    <input type="range" min="1" max="5" value={s2Ratings[2]} onChange={(event) => setS2Ratings([...s2Ratings.slice(0,2), event.target.value, ...s2Ratings.slice(3,10)])} />
                                </td>
                            </tr>
                            <tr>
                                <td>4.</td>
                                <td>Integrity: Honesty, sincerity and sobriety</td>
                                <td>
                                    <input type="range"  min="1" max="5" value={s2Ratings[3]} onChange={(event) => setS2Ratings([...s2Ratings.slice(0,3), event.target.value, ...s2Ratings.slice(4,10)])} />
                                </td>
                            </tr>
                            <tr>
                                <td>5.</td>
                                <td>Housekeeping: General appearance and cleanliness</td>
                                <td>
                                    <input type="range"  min="1" max="5" value={s2Ratings[4]} onChange={(event) => setS2Ratings([...s2Ratings.slice(0,4), event.target.value, ...s2Ratings.slice(5,10)])} />
                                </td>
                            </tr>
                            <tr>
                                <td>6.</td>
                                <td>Planning and delegation: Planning of tasks/jobs and delegation to team members.</td>
                                <td>
                                    <input type="range"  min="1" max="5" value={s2Ratings[5]} onChange={(event) => setS2Ratings([...s2Ratings.slice(0,5), event.target.value, ...s2Ratings.slice(6,10)])} />
                                </td>
                            </tr>
                            <tr>
                                <td>7.</td>
                                <td>Emergency response / behaviour under stress: Responses to work pressure and handling of critical situations.</td>
                                <td>
                                    <input type="range"  min="1" max="5" value={s2Ratings[6]} onChange={(event) => setS2Ratings([...s2Ratings.slice(0,6), event.target.value, ...s2Ratings.slice(7,10)])} />
                                </td>
                            </tr>
                            <tr>
                                <td>8.</td>
                                <td>Documentation: Reports, Verification, Record keeping and Filing of documents.</td>
                                <td>
                                    <input type="range"  min="1" max="5" value={s2Ratings[7]} onChange={(event) => setS2Ratings([...s2Ratings.slice(0,7), event.target.value, ...s2Ratings.slice(8,10)])} />
                                </td>
                            </tr>
                            <tr>
                                <td>9.</td>
                                <td>Relationship with colleagues: Behaviour with seniors/peers and treatment of subordinates.</td>
                                <td>
                                    <input type="range"  min="1" max="5" value={s2Ratings[8]} onChange={(event) => setS2Ratings([...s2Ratings.slice(0,8), event.target.value, ...s2Ratings.slice(9,10)])} />
                                </td>
                            </tr>
                            <tr>
                                <td>10.</td>
                                <td>Scope for added responsibility.</td>
                                <td>
                                    <input type="range" min="1" max="5" value={s2Ratings[9]} onChange={(event) => setS2Ratings([...s2Ratings.slice(0,9), event.target.value])} />
                                </td>
                            </tr>
                        </table>
                        <hr></hr>
                        <table CELLPADDING='2' className='mb-3'>
                            <tr>
                                <td>Appraiser's Comments including any specific Training or Career Development Requests:</td>
                                <td><input type="text" onChange={(event) => setAprraiserComment(event.target.value)}></input></td>
                            </tr>
                            <tr>
                                <td>Employee&#39;s Comments including any identified Training Needs &amp; Effectiveness of training carried out during the assessment period:</td>
                                <td><input type="text" onChange={(event) => setEmployeeComment(event.target.value)}></input></td>
                            </tr>
                        </table>
                        <div style={{textAlign:'center'}} onClick={handleSupportSubmit}><button>Submit</button></div><br />
                    </form>
                </div>
            }
            {
                //For the MD + HR comment, that will be done seperately from this form --> done by them in their "manage" tab
                appraisalType === "Key" &&
                <div>
                    <div style={{textAlign: 'center'}}>
                        <label>Please select your position</label> <br />
                        <select name="appraiser position" onChange={(event) => setAppraiserPosition(event.target.value)} className='mb-3'>
                            <option value="Employee">Employee</option>
                            <option value="Supervisor">Supervisor</option>
                            <option value="HR">HR</option>
                        </select>
                    </div>
			    <div style={{textAlign: 'center'}}>
                        <b>* Note:</b> Please only fill in the Form ID feild if you are filling in this form due to a request visible on the "Claims Management" page. <b>Leave it empty</b> if you are creating the form.
                    </div>
                    <hr></hr>
                    <table CELLPADDING='6'>
                            <tr>
                                <th>Form ID *</th>
                                <td><input onChange={(event) => setAppraisalId(event.target.value)}></input></td>
                                <th>Employee Name</th>
                                <td><input onChange={(event) => setEmployeeName(event.target.value)}></input></td>
                            </tr>
                            <tr>
                                <th>Employee ID</th>
                                <td><input onChange={(event) => setEmployeeID(event.target.value)}></input></td>
                                <th>Department</th>
                                <td><input onChange={(event) => setDepartment(event.target.value)}></input></td>
                            </tr>
                            <tr>
                                <th>Position</th>
                                <td><input onChange={(event) => setPosition(event.target.value)}></input></td>
                                <th>Supervisor</th>
                                <td><input onChange={(event) => setSupervisor(event.target.value)}></input></td>
                            </tr>
                            <tr>
                                <th>Supervisor ID</th>
                                <td><input onChange={(event) => setSupervisorID(event.target.value)}></input></td>
                                <th>Commencement Date</th>
                                <td><input type="date" onChange={(event) => setCommencementDate(event.target.value)}></input></td>
                            </tr>
                            <tr> 
                                <th>Appraisal From</th>
                                <td><input type="date" onChange={(event) => setAppraisalPeriod([event.target.value, ...appraisalPeriod.slice(1,2)])}></input></td>
                                <th>Appraisal To</th>
                                <td><input type="date" onChange={(event) => setAppraisalPeriod([...appraisalPeriod.slice(0,1), event.target.value])}></input></td>
                            </tr>
			    <tr>
				<th>Date Of Appraisal</th>
                                <td><input type="date" onChange={(event) => setDateOfAppraisal(event.target.value)}></input></td>
			    </tr>
                    </table>
                    <hr></hr>
                    {appraiserPosition === "Employee" &&
                    <div>
                        <h5>Section II: Performance of Key Job Duties</h5>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Instruction</th>
                                <th>Employee</th>
                            </tr>
                            <tr>
                                <td>Rate your performance on your job duties assigned</td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s2Rating([event.target.value, ...form2s2Rating.slice(1,2)])}></input></td>
                            </tr>
                            <tr>
                                <td>Comments:</td>
                                <td><input value={form2s2Comments[0]} onChange={(event) => setForm2s2Comments([event.target.value, ...form2s2Comments.slice(1,2)])}></input></td>
                            </tr>
                        </table>
                        <hr></hr>
                        <h5>Section III: General Work Performance</h5>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Factor</th>
                                <th>Employee</th>
                            </tr>
                            <tr>
                                <td><strong>1. QUANTITY OF WORK (7%):</strong>
The extent to which the employee accomplishes assigned work of a
specified quality within a specified time period.</td>
                                <td><input onChange={(event) => setForm2s3EmployeeRatings([event.target.value, ...form2s3EmployeeRatings.slice(1,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>2. QUALITY OF WORK (15%)</strong>
Consider accuracy, thoroughness, effectiveness.</td>
                                <td><input onChange={(event) => setForm2s3EmployeeRatings([...form2s3EmployeeRatings.slice(0,1), event.target.value, ...form2s3EmployeeRatings.slice(2,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>3. KNOWLEDGE OF JOB (15%)</strong>
The extent to which the employee demonstrates how and why to do all
phases of assigned work, given the employee&#39;s length of time in his/her
current position.</td>
                                <td><input onChange={(event) => setForm2s3EmployeeRatings([...form2s3EmployeeRatings.slice(0,2), event.target.value, ...form2s3EmployeeRatings.slice(3,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>4. FLEXIBILITY (7%)</strong>
Consider performance under pressure and handling of multiple
assignments.</td>
                                <td><input onChange={(event) => setForm2s3EmployeeRatings([...form2s3EmployeeRatings.slice(0,3), event.target.value, ...form2s3EmployeeRatings.slice(4,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>5. INITIATIVE (8%)</strong>
Consider the extent to which the employee sets own constructive work
practice and recommends and creates own procedures.</td>
                                <td><input onChange={(event) => setForm2s3EmployeeRatings([...form2s3EmployeeRatings.slice(0,4), event.target.value, ...form2s3EmployeeRatings.slice(5,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>6. DEPENDABILITY (10%)</strong>
Consider the extent to which the employee completes assignments on
time and carries out instructions.</td>
                                <td><input onChange={(event) => setForm2s3EmployeeRatings([...form2s3EmployeeRatings.slice(0,5), event.target.value, ...form2s3EmployeeRatings.slice(6,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>7. INTERPERSONAL RELATIONS (10%)</strong>
Consider the extent to which the employee is cooperative, considerate,
and tactful in dealing with supervisors, subordinates, colleagues and
others and is willing to accept constructive criticism.</td>
                                <td><input onChange={(event) => setForm2s3EmployeeRatings([...form2s3EmployeeRatings.slice(0,6), event.target.value, ...form2s3EmployeeRatings.slice(7,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>8. ADHERENCE TO COMPANY ALL POLICIES &amp; PROCEDURES (10%)</strong>
Adherence to policies and procedures</td>
                                <td><input onChange={(event) => setForm2s3EmployeeRatings([...form2s3EmployeeRatings.slice(0,7), event.target.value, ...form2s3EmployeeRatings.slice(8,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>9. ORGANIZATION (8%)</strong>
The extent of projects is well conceived, analyzed, and carried out
systematically?</td>
                                <td><input onChange={(event) => setForm2s3EmployeeRatings([...form2s3EmployeeRatings.slice(0,8), event.target.value, ...form2s3EmployeeRatings.slice(9,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>10. PROBLEM SOLVING SKILL (10%)</strong></td>
                                <td><input onChange={(event) => setForm2s3EmployeeRatings([...form2s3EmployeeRatings.slice(0,9), event.target.value])}></input></td>
                            </tr>
                        </table>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Employee's comments</th>
                            </tr>
                            <tr>
                                <td><input onChange={(event) => setForm2s3Comments([event.target.value, ...form2s3Comments.slice(1,2)])}></input></td>
                            </tr>
                        </table>
                        <hr></hr>
                        <h5>Section IV: Management Performance Factor (For Supervisor Grade Only)</h5>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Metric</th>
                                <th>Employee</th>
                            </tr>
                            <tr>
                                <td><strong>1. INNOVATION (20%)</strong>
The extent to which the individual provides guidance and opportunities
to staff members for their development and advancement in the
Company.</td>
                                <td><input onChange={(event) => setForm2s4Employee([event.target.value, ...form2s4Employee.slice(1,4)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>2. DELEGATION (20%)</strong>
The extent to which the supervisor delegate work to team members
clearly and monitor those work regularly and effectively.</td>
                                <td><input onChange={(event) => setForm2s4Employee([...form2s4Employee.slice(0,1), event.target.value, ...form2s4Employee.slice(2,4)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>3. LEADERSHIP (30%)</strong>
The extent to which the supervisor provides clear direction, instruction
and goal to team members and dedicates considerable effort to
develop the skill of team members. The Supervisor shows strong
leadership and confidence.</td>
                                <td><input onChange={(event) => setForm2s4Employee([...form2s4Employee.slice(0,2), event.target.value, ...form2s4Employee.slice(3,4)])}></input></td>  
                            </tr>
                            <tr>
                                <td><strong>4. DECISION MAKING (30%)</strong>
Be able to weight the positives and negatives of each option among all
alternatives when making decision. Be able to forecast the outcome of
each option and base on all of those items, determine which option is
the best of that particular situation.</td>
                                <td><input onChange={(event) => setForm2s4Employee([...form2s4Employee.slice(0,3), event.target.value])}></input></td>  
                            </tr>
                        </table>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Employee's comments</th>
                            </tr>
                            <tr>
                                <td><input onChange={(event) => setForm2s4Comments([event.target.value, ...form2s4Comments.slice(1,2)])}></input></td>
                            </tr>
                        </table>
                        <h5>Section V: Achievement of Annual Goals Set for the Employee</h5>
                        <table CELLPADDING='6'>
                            <tr>
                                <td>Part A) Employee’s Final Comments Including any specific training &amp; Career Development Requests:</td>
                                <td><input type="text" onChange={(event) => setForm2s5Comments([event.target.value, ...form2s5Comments.slice(1,2)])}></input></td>
                            </tr>
                        </table>
                    </div>
                    }
                    {appraiserPosition === "Supervisor" && <div>
                        <h5>Section I: Goal Setting By Supervisor</h5>
                        <h6>Following Annual Goals have been set for the employee for the current year:</h6>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Standard aspects</th>
                                <th>Measurable Goals/Targets</th>
                                <th>Year End Assessment for each goal</th>
                            </tr>
                            <tr>
                                <td>1. Customer Satisfaction Survey Score</td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Target([event.target.value, ...form2s1Target.slice(1, 10)])}></input></td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Rating([event.target.value, ...form2s1Rating.slice(1, 10)])}></input></td>
                            </tr>
                            <tr>
                                <td>2. Audit Performance</td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Target([...form2s1Target.slice(0, 1), event.target.value, ...form2s1Target.slice(2, 10)])}></input></td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Rating([...form2s1Rating.slice(0, 1), event.target.value, ...form2s1Rating.slice(2, 10)])}></input></td>
                            </tr>
                            <tr>
                                <td>3. PSC Performance</td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Target([...form2s1Target.slice(0, 2), event.target.value, ...form2s1Target.slice(3, 10)])}></input></td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Rating([...form2s1Rating.slice(0, 2), event.target.value, ...form2s1Rating.slice(3, 10)])}></input></td>
                            </tr>
                            <tr>
                                <td>4. Ship/Shore Staff Retention</td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Target([...form2s1Target.slice(0, 3), event.target.value, ...form2s1Target.slice(4, 10)])}></input></td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Rating([...form2s1Rating.slice(0, 3), event.target.value, ...form2s1Rating.slice(4, 10)])}></input></td>
                            </tr>
                            <tr>
                                <td>5. Personal Safety (LTIF, TRCF, Injuries, Fatalities</td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Target([...form2s1Target.slice(0, 4), event.target.value, ...form2s1Target.slice(5, 10)])}></input></td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Rating([...form2s1Rating.slice(0, 4), event.target.value, ...form2s1Rating.slice(5, 10)])}></input></td>
                            </tr>
                            <tr>
                                <td>6. Near Misses</td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Target([...form2s1Target.slice(0, 5), event.target.value, ...form2s1Target.slice(6, 10)])}></input></td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Rating([...form2s1Rating.slice(0, 5), event.target.value, ...form2s1Rating.slice(6, 10)])}></input></td>
                            </tr>
                            <tr>
                                <td>7. Best Practices</td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Target([...form2s1Target.slice(0, 6), event.target.value, ...form2s1Target.slice(7, 10)])}></input></td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Rating([...form2s1Rating.slice(0, 6), event.target.value, ...form2s1Rating.slice(7, 10)])}></input></td>
                            </tr>
                            <tr>
                                <td>8. Vessel Off-Hire</td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Target([...form2s1Target.slice(0, 7), event.target.value, ...form2s1Target.slice(8, 10)])}></input></td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Rating([...form2s1Rating.slice(0, 7), event.target.value, ...form2s1Rating.slice(8, 10)])}></input></td>
                            </tr>
                            <tr>
                                <td>9. PMS/ Breakdowns</td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Target([...form2s1Target.slice(0, 8), event.target.value, ...form2s1Target.slice(9, 10)])}></input></td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Rating([...form2s1Rating.slice(0, 8), event.target.value, ...form2s1Rating.slice(9, 10)])}></input></td>
                            </tr>
                            <tr>
                                <td>10. Environment Objectives</td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Target([...form2s1Target.slice(0, 9), event.target.value])}></input></td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s1Rating([...form2s1Rating.slice(0, 9), event.target.value])}></input></td>
                            </tr>
                        </table>
                        <hr></hr>
                        <h5>Section II: Performance of Key Job Duties</h5>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Instruction</th>
                                <th>Supervisor</th>
                            </tr>
                            <tr>
                                <td>Rate your performance on your job duties assigned</td>
                                <td><input type="number" min="1" max="5" onChange={(event) => setForm2s2Rating([...form2s2Rating.slice(0,1), event.target.value])}></input></td>
                            </tr>
                            <tr>
                                <td>Comments:</td>
                                <td><input value={form2s2Comments[1]} onChange={(event) => setForm2s2Comments([...form2s2Comments.slice(0, 1), event.target.value])}></input></td>
                            </tr>
                        </table>
                        <hr></hr>
                        <h5>Section III: General Work Performance</h5>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Factor</th>
                                <th>Supervisor</th>
                            </tr>
                            <tr>
                                <td><strong>1. QUANTITY OF WORK (7%):</strong>
The extent to which the employee accomplishes assigned work of a
specified quality within a specified time period.</td>
                                <td><input onChange={(event) => setForm2s3SupervisorRatings([event.target.value, ...form2s3SupervisorRatings.slice(1,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>2. QUALITY OF WORK (15%)</strong>
Consider accuracy, thoroughness, effectiveness.</td>
                                <td><input onChange={(event) => setForm2s3SupervisorRatings([...form2s3SupervisorRatings.slice(0,1), event.target.value, ...form2s3SupervisorRatings.slice(2,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>3. KNOWLEDGE OF JOB (15%)</strong>
The extent to which the employee demonstrates how and why to do all
phases of assigned work, given the employee&#39;s length of time in his/her
current position.</td>
                                <td><input onChange={(event) => setForm2s3SupervisorRatings([...form2s3SupervisorRatings.slice(0,2), event.target.value, ...form2s3SupervisorRatings.slice(3,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>4. FLEXIBILITY (7%)</strong>
Consider performance under pressure and handling of multiple
assignments.</td>
                                <td><input onChange={(event) => setForm2s3SupervisorRatings([...form2s3SupervisorRatings.slice(0,3), event.target.value, ...form2s3SupervisorRatings.slice(4,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>5. INITIATIVE (8%)</strong>
Consider the extent to which the employee sets own constructive work
practice and recommends and creates own procedures.</td>
                                <td><input onChange={(event) => setForm2s3SupervisorRatings([...form2s3SupervisorRatings.slice(0,4), event.target.value, ...form2s3SupervisorRatings.slice(5,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>6. DEPENDABILITY (10%)</strong>
Consider the extent to which the employee completes assignments on
time and carries out instructions.</td>
                                <td><input onChange={(event) => setForm2s3SupervisorRatings([...form2s3SupervisorRatings.slice(0,5), event.target.value, ...form2s3SupervisorRatings.slice(6,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>7. INTERPERSONAL RELATIONS (10%)</strong>
Consider the extent to which the employee is cooperative, considerate,
and tactful in dealing with supervisors, subordinates, colleagues and
others and is willing to accept constructive criticism.</td>
                                <td><input onChange={(event) => setForm2s3SupervisorRatings([...form2s3SupervisorRatings.slice(0,6), event.target.value, ...form2s3SupervisorRatings.slice(7,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>8. ADHERENCE TO COMPANY ALL POLICIES &amp; PROCEDURES (10%)</strong>
Adherence to policies and procedures</td>
                                <td><input onChange={(event) => setForm2s3SupervisorRatings([...form2s3SupervisorRatings.slice(0,7), event.target.value, ...form2s3SupervisorRatings.slice(8,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>9. ORGANIZATION (8%)</strong>
The extent of projects is well conceived, analyzed, and carried out
systematically?</td>
                                <td><input onChange={(event) => setForm2s3SupervisorRatings([...form2s3SupervisorRatings.slice(0,8), event.target.value, ...form2s3SupervisorRatings.slice(9,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>10. PROBLEM SOLVING SKILL (10%)</strong></td>
                                <td><input onChange={(event) => setForm2s3SupervisorRatings([...form2s3SupervisorRatings.slice(0,9), event.target.value])}></input></td>
                            </tr>
                        </table>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Supervisor's comments</th>
                            </tr>
                            <tr>
                                <td><input onChange={(event) => setForm2s3Comments([...form2s3Comments.slice(0,1), event.target.value])}></input></td>
                            </tr>
                        </table>
                        <hr></hr>
                        <h5>Section IV: Management Performance Factor (For Supervisor Grade Only)</h5>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Metric</th>
                                <th>Supervisor</th>
                            </tr>
                            <tr>
                                <td><strong>1. INNOVATION (20%)</strong>
The extent to which the individual provides guidance and opportunities
to staff members for their development and advancement in the
Company.</td>
                                <td><input onChange={(event) => setForm2s4Supervisor([event.target.value, ...form2s4Supervisor.slice(1,4)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>2. DELEGATION (20%)</strong>
The extent to which the supervisor delegate work to team members
clearly and monitor those work regularly and effectively.</td>
                                <td><input onChange={(event) => setForm2s4Supervisor([...form2s4Supervisor.slice(0,1), event.target.value, ...form2s4Supervisor.slice(2,4)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>3. LEADERSHIP (30%)</strong>
The extent to which the supervisor provides clear direction, instruction
and goal to team members and dedicates considerable effort to
develop the skill of team members. The Supervisor shows strong
leadership and confidence.</td>
                                <td><input onChange={(event) => setForm2s4Supervisor([...form2s4Supervisor.slice(0,2), event.target.value, ...form2s4Supervisor.slice(3,4)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>4. DECISION MAKING (30%)</strong>
Be able to weight the positives and negatives of each option among all
alternatives when making decision. Be able to forecast the outcome of
each option and base on all of those items, determine which option is
the best of that particular situation.</td>
                                <td><input onChange={(event) => setForm2s4Supervisor([...form2s4Supervisor.slice(0,3), event.target.value])}></input></td>
                            </tr>
                        </table>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Supervisor's comments</th>
                            </tr>
                            <tr>
                                <td><input onChange={(event) => setForm2s4Comments([...form2s4Comments.slice(0,1), event.target.value])}></input></td>
                            </tr>
                        </table>
                        <hr></hr>
                        <h5>Section V: Achievement of Annual Goals Set for the Employee</h5>
                        <table>
                            <tr>
                                <td>Part B) Supervisor’s Final Comments including any Identified Training Needs &amp; Effectiveness of the
training carried out during the assessment period.</td>
                                <td><input  type="text" onChange={(event) => setForm2s5Comments([...form2s5Comments.slice(0,1), event.target.value])}></input></td>
                            </tr>
                        </table>
                    </div>}
                    {appraiserPosition === "HR" && <div>
                    <h5>Section III: General Work Performance</h5>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Factor</th>
                                <th>HR</th>
                            </tr>
                            <tr>
                                <td><strong>1. QUANTITY OF WORK (7%):</strong>
The extent to which the employee accomplishes assigned work of a
specified quality within a specified time period.</td>
                                <td><input onChange={(event) => setForm2s3HrRatings([event.target.value, ...form2s3HrRatings.slice(1,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>2. QUALITY OF WORK (15%)</strong>
Consider accuracy, thoroughness, effectiveness.</td>
                                <td><input onChange={(event) => setForm2s3HrRatings([...form2s3HrRatings.slice(0,1), event.target.value, ...form2s3HrRatings.slice(2,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>3. KNOWLEDGE OF JOB (15%)</strong>
The extent to which the employee demonstrates how and why to do all
phases of assigned work, given the employee&#39;s length of time in his/her
current position.</td>
                                <td><input onChange={(event) => setForm2s3HrRatings([...form2s3HrRatings.slice(0,2), event.target.value, ...form2s3HrRatings.slice(3,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>4. FLEXIBILITY (7%)</strong>
Consider performance under pressure and handling of multiple
assignments.</td>
                                <td><input onChange={(event) => setForm2s3HrRatings([...form2s3HrRatings.slice(0,3), event.target.value, ...form2s3HrRatings.slice(4,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>5. INITIATIVE (8%)</strong>
Consider the extent to which the employee sets own constructive work
practice and recommends and creates own procedures.</td>
                                <td><input onChange={(event) => setForm2s3HrRatings([...form2s3HrRatings.slice(0,4), event.target.value, ...form2s3HrRatings.slice(5,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>6. DEPENDABILITY (10%)</strong>
Consider the extent to which the employee completes assignments on
time and carries out instructions.</td>
                                <td><input onChange={(event) => setForm2s3HrRatings([...form2s3HrRatings.slice(0,5), event.target.value, ...form2s3HrRatings.slice(6,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>7. INTERPERSONAL RELATIONS (10%)</strong>
Consider the extent to which the employee is cooperative, considerate,
and tactful in dealing with supervisors, subordinates, colleagues and
others and is willing to accept constructive criticism.</td>
                                <td><input onChange={(event) => setForm2s3HrRatings([...form2s3HrRatings.slice(0,6), event.target.value, ...form2s3HrRatings.slice(7,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>8. ADHERENCE TO COMPANY ALL POLICIES &amp; PROCEDURES (10%)</strong>
Adherence to policies and procedures</td>
                                <td><input onChange={(event) => setForm2s3HrRatings([...form2s3HrRatings.slice(0,7), event.target.value, ...form2s3HrRatings.slice(8,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>9. ORGANIZATION (8%)</strong>
The extent of projects is well conceived, analyzed, and carried out
systematically?</td>
                                <td><input onChange={(event) => setForm2s3HrRatings([...form2s3EmployeeRatings.slice(0,8), event.target.value, ...form2s3EmployeeRatings.slice(9,10)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>10. PROBLEM SOLVING SKILL (10%)</strong></td>
                                <td><input onChange={(event) => setForm2s3HrRatings([...form2s3HrRatings.slice(0,9), event.target.value])}></input></td>
                            </tr>
                        </table>
                        <hr></hr>
                        <h5>Section IV: Management Performance Factor (For Supervisor Grade Only)</h5>
                        <table CELLPADDING='6'>
                            <tr>
                                <th>Metric</th>
                                <th>HR</th>
                            </tr>
                            <tr>
                                <td><strong>1. INNOVATION (20%)</strong>
The extent to which the individual provides guidance and opportunities
to staff members for their development and advancement in the
Company.</td>
                                <td><input onChange={(event) => setForm2s4Hr([event.target.value, ...form2s4Hr.slice(1,4)])}></input></td>
                            </tr>
                            <tr>
                                <td><strong>2. DELEGATION (20%)</strong>
The extent to which the supervisor delegate work to team members
clearly and monitor those work regularly and effectively.</td>
                                <td><input onChange={(event) => setForm2s4Hr([...form2s4Hr.slice(0,1), event.target.value, ...form2s4Hr.slice(2,4)])}></input></td>   
                            </tr>
                            <tr>
                                <td><strong>3. LEADERSHIP (30%)</strong>
The extent to which the supervisor provides clear direction, instruction
and goal to team members and dedicates considerable effort to
develop the skill of team members. The Supervisor shows strong
leadership and confidence.</td>
                                <td><input onChange={(event) => setForm2s4Hr([...form2s4Hr.slice(0,2), event.target.value, ...form2s4Hr.slice(3,4)])}></input></td>   
                            </tr>
                            <tr>
                                <td><strong>4. DECISION MAKING (30%)</strong>
Be able to weight the positives and negatives of each option among all
alternatives when making decision. Be able to forecast the outcome of
each option and base on all of those items, determine which option is
the best of that particular situation.</td>
                                <td><input onChange={(event) => setForm2s4Hr([...form2s4Hr.slice(0,3), event.target.value])}></input></td>   
                            </tr>
                        </table>
                    </div>}
                        <div style={{textAlign:'center'}} onClick={handleKeySubmit}><button>Submit</button></div><br />
                </div>
            }
            {
                appraisalType === "Probitionary" &&
                <div>
                    <p><strong>Instructions to Evaluator:</strong> <br /> Evaluators should refer to the employee&#39;s job description when completing
this form; the evaluation should focus on the employee&#39;s ability to perform the job duties listed in the
job description. Employees should be evaluated before the end of the probationary period. Indicate the
evaluation of the employee&#39;s job performance by writing a number between 1 and 5 on the blank line to
the right of each attribute. Use the scale from above.</p>
                <table CELLPADDING='6'>
                            <tr>
                                <th>Employee Name</th>
                                <td><input onChange={(event) => setEmployeeName(event.target.value)}></input></td>
                                <th>Department</th>
                                <td><input onChange={(event) => setDepartment(event.target.value)}></input></td>
                            </tr>
                            <tr>
                                <th>Position</th>
                                <td><input onChange={(event) => setPosition(event.target.value)}></input></td>
                                <th>Evaluation Date</th>
                                <td><input type="date" onChange={(event) => setEvaluationDate(event.target.value)}></input></td>
                            </tr>
                            <tr>
                                <th>Last Day of Probation</th>
                                <td><input type="date" onChange={(event) => setLastDayProbation(event.target.value)}></input></td>
                                <th>Commencement Date</th>
                                <td><input type="date" onChange={(event) => setCommencementDate(event.target.value)}></input></td>
                            </tr>
                </table>
                <hr></hr>
                <table CELLPADDING='6'>
                    <tr>
                        <th>Attribute</th>
                        <th>Job Performance</th>
                    </tr>
                    <tr>
                        <td><strong>QUANTITY OF WORK:</strong> The extent to which the employee accomplishes assigned work of a specified quality
within a specified time period</td>
                        <td><input type="number" min="1" max="5" onChange={(event) => setForm3s1Rating([event.target.value, ...form3s1Rating.slice(1,8)])}></input></td>
                    </tr>
                    <tr>
                        <td><strong>QUALITY OF WORK:</strong> The extent to which the employee&#39;s work is well executed, thorough, effective,
accurate</td>
                        <td><input type="number" min="1" max="5" onChange={(event) => setForm3s1Rating([...form3s1Rating.slice(0,1), event.target.value, ...form3s1Rating.slice(2,8)])}></input></td>
                    </tr>
                    <tr>
                        <td><strong>KNOWLEDGE OF JOB: </strong>
The extent to which the employee knows and demonstrates how and why to do all
phases of assigned work, given the employee&#39;s length of time in his/her current
position</td>
                        <td><input type="number" min="1" max="5" onChange={(event) => setForm3s1Rating([...form3s1Rating.slice(0,2), event.target.value, ...form3s1Rating.slice(3,8)])}></input></td>
                    </tr>
                    <tr>
                        <td><strong>RELATIONS WITH SUPERVISOR: </strong>
The manner in which the employee responds to supervisory directions and comments.
The extent to which the employee seeks counsel from supervisor on ways to improves
performance and follows same</td>
                        <td><input type="number" min="1" max="5" onChange={(event) => setForm3s1Rating([...form3s1Rating.slice(0,3), event.target.value, ...form3s1Rating.slice(4,8)])}></input></td>
                    </tr>
                    <tr>
                        <td><strong>COOPERATION WITH OTHERS: </strong>
The extent to which the employee gets along with other individuals. Consider the
employee&#39;s tact, courtesy, and effectiveness in dealing with co-workers, subordinates
supervisors, and customers</td>
                        <td><input type="number" min="1" max="5" onChange={(event) => setForm3s1Rating([...form3s1Rating.slice(0,4), event.target.value, ...form3s1Rating.slice(5,8)])}></input></td>
                    </tr>
                    <tr>
                        <td><strong>ATTENDANCE AND RELIABILITY: </strong>
The extent to which employee arrives on time and demonstrates consistent
attendance; the extent to which the employee contacts supervisor on a timely basis
when employee will be late or absent</td>
                        <td><input type="number" min="1" max="5" onChange={(event) => setForm3s1Rating([...form3s1Rating.slice(0,5), event.target.value, ...form3s1Rating.slice(6,8)])}></input></td>
                    </tr>
                    <tr>
                        <td><strong>INITIATIVE AND CREATIVITY: </strong>
The extent to which the employee is self- directed, resourceful and creative in meeting job objectives; consider how well the employee follows through on assignments and
modifies or develops new ideas, methods, or procedures to effectively meet changing
circumstances</td>
                        <td><input type="number" min="1" max="5" onChange={(event) => setForm3s1Rating([...form3s1Rating.slice(0,6), event.target.value, ...form3s1Rating.slice(7,8)])}></input></td>
                    </tr>
                    <tr>
                        <td><strong>CAPACITY TO DEVELOP: </strong>
The extent to which the employee demonstrates the ability and willingness to accept
new/more complex duties/responsibilities</td>
                        <td><input type="number" min="1" max="5" onChange={(event) => setForm3s1Rating([...form3s1Rating.slice(0,7), event.target.value])}></input></td>
                    </tr>
                </table>
                <table CELLPADDING='6'>
                    <tr>
                        <th>Employee Comments</th>
                        <td><input type="text" onChange={(event) => setForm3s1Comments([event.target.value, ...form3s1Comments.slice(1,2)])}></input></td>
                    </tr>
                    <tr>
                        <th>Evaluator Comments</th>
                        <td><input type="text" onChange={(event) => setForm3s1Comments([...form3s1Comments.slice(0,1), event.target.value])}></input></td>
                    </tr>
                </table>
                <hr></hr>
                <h6>TO BE COMPLETED AT LAST EVALUATION BEFORE END OF PROBATIONARY PERIOD:</h6>
                <table>
                    <tr>
                        <td>I recommend this probationary employee become permanent and continuous <strong>(1 for yes; 0 for no).</strong></td>
                        <td><input type="number" min="0" max="1" onChange={(event) => setForm3s1Checkboxes([event.target.value, ...form3s1Checkboxes.slice(1,4)])}></input></td>
                    </tr>
                    <tr>
                        <td>I recommend to extend this probationary employee’s probation for 1 more month and further evaluate employee’s performance <strong>(1 for yes; 0 for no).</strong></td>
                        <td><input type="number" min="0" max="1" onChange={(event) => setForm3s1Checkboxes([...form3s1Checkboxes.slice(0,1), event.target.value, ...form3s1Checkboxes.slice(2,4)])}></input></td>
                    </tr>
                    <tr>
                        <td>I recommend this probationary employee be dismissed before the end of the probationary period and will submit the appropriate forms <strong>(1 for yes; 0 for no).</strong></td>
                        <td><input type="number" min="0" max="1" onChange={(event) => setForm3s1Checkboxes([...form3s1Checkboxes.slice(0,2), event.target.value, ...form3s1Checkboxes.slice(3,4)])}></input></td>
                    </tr>
                    <tr>
                        <td>Employee resigned before completion of probationary period. (It is important that SHR receive this form even if employee has resigned <strong>(1 for yes; 0 for no).</strong></td>
                        <td><input type="number" min="0" max="1" onChange={(event) => setForm3s1Checkboxes([...form3s1Checkboxes.slice(0,3), event.target.value])}></input></td>
                    </tr>
                </table>
                <div style={{textAlign:'center'}} onClick={handleProbitionarySubmit}><button>Submit</button></div><br />
                </div>
            }
            {
                appraisalType === "Self" &&
                <div>
                    <p>
                    <strong>Instructions:</strong> As an employee, you are encouraged to use this form as a guide, answering the questions
where applicable. This is your opportunity to participate in the performance review process and, together
with your supervisor, identify areas of for career growth and new learning.
                    </p>
                    <table CELLPADDING='6'>
                            <tr>
                                <th>Employee Name</th>
                                <td><input onChange={(event) => setEmployeeName(event.target.value)}></input></td>
                                <th>Department</th>
                                <td><input onChange={(event) => setDepartment(event.target.value)}></input></td>
                            </tr>
                            <tr>
                                <th>Supervisor</th>
                                <td><input onChange={(event) => setSupervisor(event.target.value)}></input></td>
                                <th>Supervisor ID</th>
                                <td><input onChange={(event) => setSupervisorID(event.target.value)}></input></td>
                            </tr>
                            <tr>
                                <th>Commencement Date</th>
                                <td><input type="date" onChange={(event) => setCommencementDate(event.target.value)}></input></td>
                                <th>Evaluation Date</th>
                                <td><input type="date" onChange={(event) => setEvaluationDate(event.target.value)}></input></td>
                            </tr>
                            <tr>
                                <th>Position</th>
                                <td><input onChange={(event) => setPosition(event.target.value)}></input></td>
                            </tr>
                    </table>
                    <hr></hr>
                    <h4 style={{textAlign: 'center'}}>CONTRIBUTIONS</h4>
                    <table CELLPADDING='6'>
                        <tr>
                            <th>Begin your self-assessment by describing your accomplishments against the goals identified in your previous year’s appraisal.</th>
                        </tr>
                        <tr>
                            <td><input type="text" onChange={(event) => setForm4Responses([event.target.value, ...form4Responses.slice(1,14)])}></input></td>
                        </tr>
                        <tr>
                            <th>Cite examples of successful accomplishments since your last review (assignments completed, projects, presentations, etc.). Identify any significant results.</th>
                        </tr>
                        <tr>
                            <td><input type="text" onChange={(event) => setForm4Responses([...form4Responses.slice(0,1), event.target.value, ...form4Responses.slice(2,14)])}></input></td>
                        </tr>
                        <tr>
                            <th>If applicable, identify responsibilities that may have been added or removed from your job during this review period.</th>
                        </tr>
                        <tr>
                            <td><input type="text" onChange={(event) => setForm4Responses([...form4Responses.slice(0,2), event.target.value, ...form4Responses.slice(3,14)])}></input></td>
                        </tr>
                        <tr>
                            <th>What specific goals would you like to accomplish in the year ahead?</th>
                        </tr>
                        <tr>
                            <td><input type="text" onChange={(event) => setForm4Responses([...form4Responses.slice(0,3), event.target.value, ...form4Responses.slice(4,14)])}></input></td>
                        </tr>
                        <tr>
                            <th>Did you participate in team or committee activities? If appropriate, identify outcomes or results.</th>
                        </tr>
                        <tr>
                            <td><input type="text" onChange={(event) => setForm4Responses([...form4Responses.slice(0,4), event.target.value, ...form4Responses.slice(5,14)])}></input></td>
                        </tr>
                    </table>
                    <hr></hr>
                    <h4 style={{textAlign: 'center'}}>OPPORTUNITIES</h4>
                    <table CELLPADDING='6'>
                        <tr>
                            <th>List any initiatives you would like to have completed this year but were unable to? Why?</th>
                        </tr>
                        <tr>
                            <td><input type="text" onChange={(event) => setForm4Responses([...form4Responses.slice(0,5), event.target.value, ...form4Responses.slice(6,14)])}></input></td>
                        </tr>
                        <tr>
                            <th>Identify any obstacles that hindered you from effectively accomplishing your work.</th>
                        </tr>
                        <tr>
                            <td><input type="text" onChange={(event) => setForm4Responses([...form4Responses.slice(0,6), event.target.value, ...form4Responses.slice(7,14)])}></input></td>
                        </tr>
                        <tr>
                            <th>In what areas and from whom do you feel that you need added support or direction.</th>
                        </tr>
                        <tr>
                            <td><input type="text" onChange={(event) => setForm4Responses([...form4Responses.slice(0,7), event.target.value, ...form4Responses.slice(8,14)])}></input></td>
                        </tr>
                    </table>
                    <hr></hr>
                    <h4 style={{textAlign: 'center'}}>TRAINING AND PROFESSIONAL DEVELOPMENT</h4>
                    <table CELLPADDING='6'>
                        <tr>
                            <th>List any training programs or professional development you completed this past year. (seminars, on-site training classes, challenging projects, on-the-job experiences)</th>
                        </tr>
                        <tr>
                            <td><input type="text" onChange={(event) => setForm4Responses([...form4Responses.slice(0,8), event.target.value, ...form4Responses.slice(9,14)])}></input></td>
                        </tr>
                        <tr>
                            <th>Identify skills/knowledge which could increase your performance effectiveness and any other related training/learning opportunities.</th>
                        </tr>
                        <tr>
                            <td><input type="text" onChange={(event) => setForm4Responses([...form4Responses.slice(0,9), event.target.value, ...form4Responses.slice(10,14)])}></input></td>
                        </tr>
                        <tr>
                            <th>Identify professional conferences meetings professional associations or certifications in which your participation would aid in your professional development.</th>
                        </tr>
                        <tr>
                            <td><input type="text" onChange={(event) => setForm4Responses([...form4Responses.slice(0,10), event.target.value, ...form4Responses.slice(11,14)])}></input></td>
                        </tr>
                    </table>
                    <hr></hr>
                    <h4 style={{textAlign: 'center'}}>PERFORMANCE APPRAISAL DISCUSSION</h4>
                    <table CELLPADDING='6'>
                        <tr>
                            <th>List any subjects you may want to discuss at the appraisal meeting with your supervisor.</th>
                        </tr>
                        <tr>
                            <td><input type="text" onChange={(event) => setForm4Responses([...form4Responses.slice(0,11), event.target.value, ...form4Responses.slice(12,14)])}></input></td>
                        </tr>
                        <tr>
                            <th>What do you need from your supervisor to do an even better job?</th>
                        </tr>
                        <tr>
                            <td><input type="text" onChange={(event) => setForm4Responses([...form4Responses.slice(0,12), event.target.value, ...form4Responses.slice(13,14)])}></input></td>
                        </tr>
                        <tr>
                            <th>What individuals, including project team leaders or members, clients or customers, and colleagues, would you like to provide to your supervisor who could comment or provide feedback regarding your performance?</th>
                        </tr>
                        <tr>
                            <td><input type="text" onChange={(event) => setForm4Responses([...form4Responses.slice(0,13), event.target.value])}></input></td>
                        </tr>
                    </table>
                    <div style={{textAlign:'center'}} onClick={handleSelfSubmit}><button>Submit</button></div><br />
                </div>
            }
            {showSuccessMsg && 
                <div className='popupOverlay'>
                    <div className='popupContainer'>
                        <div className='popupMessage'>
                            The appraisal report is submitted
                            <br /><br />
                            <button onClick={onClose} style={{textAlign: 'center'}}>Close</button>
                        </div>
                    </div>
                </div>}
            </form>
        </div>
    )
}

export default CreateAppraisal;
