import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import Payroll from './Pages/Payroll';
import EmployeeAppraisal from './Pages/EmployeeAppraisal';
import ClaimsManagement from './Pages/ClaimsManagement';
import LeaveManagement from './Pages/LeaveManagement';
import Home from './Pages/Home';
import Manage from './Pages/Manage';
import CreateLeave from './Pages/CreateLeave';
import CreateClaim from './Pages/CreateClaim';
import CreateAppraisal from './Pages/CreateAppraisal';
import EditPayroll from './Pages/EditPayroll';
import HistoricalPayroll from './Pages/HistoricalPayroll';
import CreateAccount from './Pages/CreateAccount';
import AddPayroll from './Pages/AddPayroll';
import ClaimHistory from './Pages/ClaimHistory';
import EmployeeDirectory from './Pages/EmployeeDirectory';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Dashboard />}>
          <Route path='' element={<Home />}></Route>
          <Route path='/payroll' element={<Payroll />}></Route>
          <Route path='/historicalPayroll' element={<HistoricalPayroll />}></Route>
          <Route path='/leaves' element={<LeaveManagement />}></Route>
          <Route path='/createLeave' element={<CreateLeave />}></Route>
          <Route path='/claims' element={<ClaimsManagement />}></Route>
          <Route path='/createClaim' element={<CreateClaim />}></Route>
          <Route path='/appraisal' element={<EmployeeAppraisal />}></Route>
          <Route path='/createappraisal' element={<CreateAppraisal />}></Route>
          <Route path='/manage' element={<Manage />}></Route>
          <Route path='/editPayroll' element={<EditPayroll />}></Route>
          <Route path='/addPayroll' element={<AddPayroll />}></Route>
	  <Route path='/claimHistory' element={<ClaimHistory />}></Route>
	  <Route path='/employeeDirectory' element={<EmployeeDirectory />}></Route>
        </Route>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/createAccount' element={<CreateAccount />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
