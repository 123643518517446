import React, { useState } from 'react'
import '../style.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { alterEmployeeId } from '../store'

function Login() {

    const [values, setValues] = useState({
        email: '',
        password: ''
    })
    const navigate = useNavigate();
    axios.defaults.withCredentials = true;
    const [error, setError] = useState('');
    // const [employeeId, setEmployeeId] = useState();
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post('https://hrm.seaways-ship.com/login', values)
        .then(res => {
            if (res.data.Status === "Success") {
//                axios.get('https://hrm.seaways-ship.com/getEmployeeId/' + values.email)
//                .then(res => {
//                  console.log(res.data.Result);
//                    dispatch(alterEmployeeId(res.data.Result[0].id));
//               })
//                .then(res => {
		    console.log(res.data.id);                
                    navigate('/');
//                })
//                .catch(err => console.log(err));
            } else {
                setError(res.data.Error)
            }
        })
        .catch(err => console.log(err));
    }

    const handleCreateAccount = () => {
        navigate('/createAccount');
    }

    return (
        <div className='d-flex justify-content-center align-items-center vh-100 loginPage'>
            <div className='p-3 rounded w-25 border loginForm'>
                <div className='text-danger'>
                    {error && error}
                </div>
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <div className='mb-3'>
                        <label htmlFor="email"><strong>Email</strong></label> <br />
                        <input type="email" placeholder='Enter Email' name='email' 
                          onChange={e => setValues({...values, email: e.target.value})} className='form-control rounded-0' autoComplete='off'/>
                    </div>
                    <div className='mb-3'>
                        <label htmlFor="password"><strong>Password</strong></label> <br />
                        <input type="password" placeholder='Enter Password' name='password'
                          onChange={e => setValues({...values, password: e.target.value})} className='form-control rounded-0' />
                    </div>
                    <button type='submit' className='btn btn-success w-100 rounded-0'> Log in</button>
                </form>
                <button className='btn w-100 rounded-0 btn-secondary mt-3' onClick={handleCreateAccount}>Create Account</button>
            </div>
        </div>
    )
}

export default Login
