import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {useSelector} from 'react-redux';
import { selectEmployeeId } from '../Slice/SelectEmployeeId';
import Calendar from 'react-calendar';

/*
To do for this page:
- add logic for HR Manager to get all the key staff appraisals in "pending" section
*/

function EmployeeAppraisal() {
    const [employeeId, setEmployeeId] = useState(useSelector(selectEmployeeId));
    const [appraisalList, setAppraisalList] = useState([]);
    const [appraiserPosition, setAppraiserPosition] = useState([]);
    const [appraisalFormList, setAppraisalFormList] = useState([]);
    const [employeeNames, setEmployeeNames] = useState([]);
    const [supervisorNames, setSupervisorNames] = useState([]);
    const [highlightedDates, setHighlightedDates] = useState([new Date(2023, 7, 15)])
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('https://hrm.seaways-ship.com/getAppraisalForm/' + employeeId)
        .then(res => {
            console.log(res.data.Result);
            const tempList = []; 
            for (var i = 0; i < res.data.Result.length; ++i) {
                tempList.push(res.data.Result[i]);
            }
            setAppraisalList(tempList);
        })
        .catch(err => console.log(err));
    }, [])

    const handleClick = (event) => {
        event.preventDefault();
        navigate('/createappraisal');
    }

    const tileContent = ({date, view}) => {
        if (view === 'month' && highlightedDates.some(d => d.toDateString() === date.toDateString())) {
            return <div className="highlighted-date">{date.getDate()}</div>;
          }
          return null;
    }

    return (
        <div style={{textAlign: 'center'}}>
            <div>
                <h4>Pending Appraisal requests</h4>
                <table style={{ margin: "0 auto" }} CELLPADDING='6'>
                    <tr>
                        <th>Appraisal ID</th>
                        <th>Appraisal Type</th>
                        <th>Appraisee</th>
                        <th>Supervisor</th>
                        <th>Department</th>
                        <th>Position</th>
                    </tr>
                    {appraisalList.map(data => {
                        return <tr>
                            <td>{data.appraisal_id}</td>
                            <td>Key Employee Appraisal</td>
                            <td>{data.employee_name}</td>
                            <td>{data.supervisor}</td>
                            <td>{data.department}</td>
                            {data.appraiser_position === "Employee" && <td>Supervisor</td>}
                            {data.appraiser_position === "Supervisor" && <td>Employee</td>}
                        </tr>;
                    })}
                </table>
                {appraisalList.length === 0 && <p>No pending appraisal requests</p>}
            </div> <br />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                    <h4>Appraisal Calendar</h4>
                    {/* <Calendar
                        tileContent={tileContent}
                    /> */}
                    <table style={{ margin: "0 auto" }} CELLPADDING='6'>
                        <tr>
                            <th>Appraisal Type</th>
                            <th>Due Date</th>
                        </tr>
                        <tr>
                            <td>Self Appraisal</td>
                            <td>15th November</td>
                        </tr>
                        <tr>
                            <td>Key Staff Appraisal</td>
                            <td>7th December</td>
                        </tr>
                    </table>
                </div>
            </div> <br />
            <div>
                <button onClick={handleClick}>
                    Create new appraisal
                </button>
            </div>
        </div>
    )
}

export default EmployeeAppraisal;